import React from "react";

const BlogBoxes = ({ blogs }) => {
	return (
		<div>
			<div className=" row w-100 mx-0">
				<div className="blogs-grid">
					{blogs &&
						blogs.map((blog, index) => {
							return (
								<div className="blog" key={index}>
									<a
										href={
											blog?.categories?.includes(10)
												? `/resources/caseStudy/${blog?.id}`
												: `/resources/blog/${blog.id}`
										}
										className="news-item fadeInUp wow pb-3"
										data-wow-delay={`.${index + 2}s`}
									>
										<div className="image-holder-gallary">
											<img alt={blog?.name} src={blog.imageUrl} />
											<div className="article-type">
												{blog?.categories?.includes(10) ? "Case Study" : "Blog"}
											</div>
										</div>

										<div className="news-text-box px-3 ">
											<div>
												<h4 className="news-title main-font">
													{blog?.title?.rendered}
												</h4>
											</div>
											<div
												className="para alt-font "
												dangerouslySetInnerHTML={{
													__html: blog?.excerpt?.rendered,
												}}
											></div>
											<div className="author bottom-0 ">
												<div className="author-name">
													<span>
														{blog?.authors?.[0]?.display_name},{" "}
														<span className="desc">
															{blog?.authors?.[0]?.description}
														</span>
													</span>
													<span className="date d-block ">
														{new Date(blog?.date).toLocaleDateString("en-US", {
															month: "short",
															day: "numeric",
															year: "numeric",
														})}
													</span>
												</div>
											</div>

											{/* eslint-disable jsx-a11y/anchor-is-valid */}
											<div className="mt-3 font-weight-600 position-absolute bottom-0 learn-more-btn ">
												Read more
												<i
													className="fa fa-arrow-right ml-2"
													aria-hidden="true"
												></i>
											</div>
											<div className="d-flex align-items-center justify-content-between">
											</div>
										</div>
									</a>
								</div>
							);
						})}
				</div>
			</div>
			<div className="row w-100">
				<div className="col-12 justify-content-end d-flex">
					<a
						href="/resources"
						className="wow fadeInUp mb-4 learn-btn mt-5 text-right"
						data-wow-delay="1s"
					>
						Explore our Blogs{" "}
						<i className="fa fa-arrow-right ml-2" aria-hidden="true"></i>
					</a>
				</div>
			</div>
		</div>
	);
};

export default BlogBoxes;
