import React from "react";
import slide1video from "../../videos/pexels-rogério-casimiro-7832757 (1080p).mp4";
import slide2video from "../../videos/slide2(2).mp4";
import slide3video from "../../videos/slide1(1).mp4";
import slide5video from "../../videos/slide5.mp4";
import banner3 from "../../Images/slide3.jpg";
import micro from "../../Images/logos/Microsoft-white 2.png";
import methodical from "../../Images/logos/methodical-logo.svg";
import mendix from "../../Images/logos/Mendix-Logo.wine (1).png";
import aws from "../../Images/logos/aws.png";
import aerospike from "../../Images/logos/AEROSPIKE-logo.png";
import p1 from "../../Images/logos/Powerapps 4.png";
import { raiseGAEvent } from "../../Events/Event";
const HomeBanner = (props) => {
	return (
		<section id="home" className="p-0 no-transition h-100vh">
			<h2 className="d-none">heading</h2>
			{/*Main Slider*/}
			<div
				id="revo_thumbs_wrapper"
				className="rev_slider_wrapper fullwidthbanner-container"
			>
				<div
					id="secondary-banner"
					className="rev_slider fullwidthabanner"
					style={{ display: "none" }}
					data-version="5.4.1"
				>
					<ul>
						{/* SLIDE 1 */}
						<li
							data-index="rs-01"
							data-transition="fade"
							data-slotamount="default"
							data-easein="Power3.easeInOut"
							data-easeout="Power3.easeInOut"
							data-masterspeed={2000}
							data-fsmasterspeed={1500}
							data-param1=""
						>
							<video src={slide1video} autoPlay muted loop playsInline />
							<div className="bg-overlay bg-black opacity-5 " />
							<div className="container position-relative h-100">
								<div
									className="tp-caption tp-resizeme"
									data-x="['left','left','center','center']"
									data-hoffset="['0','0','0','0']"
									data-y="['middle','middle','middle','middle']"
									data-voffset="['-110','-110','-150','-150']"
									data-fontsize="['80','80','50','50']"
									data-whitespace="['wrap','wrap','wrap','wrap']"
									data-lineheight="100"
									data-responsive_offset="on"
									data-width="['100%','100%','90%','90%']"
									data-type="text"
									data-textalign="['left','left','center','center']"
									data-transform_idle="o:1;"
									data-transform_in="z:0;rX:0;rY:0;rZ:0;sX:0.9;sY:0.9;skX:0;skY:0;opacity:0;s:1500;e:Power3.easeInOut;"
									data-transform_out="y:[100%];s:300;e:Power2.easeInOut;s:300;e:Power2.easeInOut;"
									data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
									data-start={200}
									data-splitin="none"
									data-splitout="none"
									style={{
										zIndex: 2,
										fontWeight: 500,
										color: "#ffffff",
										fontFamily: '"Raleway", sans-serif',
										textTransform: "capitalize",
										lineHeight: "1.5 !important",
									}}
								>
									Powering the Digital Enterprise
								</div>
								<div
									className="tp-caption tp-resizeme"
									data-x="['left','left','center','center']"
									data-hoffset="['0','40','0','0']"
									data-y="['middle','middle','middle','middle']"
									data-voffset="['-20','-20','30','30']"
									data-fontsize="['30','30','25','25']"
									data-lineheight="40"
									data-whitespace="['wrap','wrap','wrap','wrap']"
									data-responsive_offset="on"
									data-width="['90%','90%','90%','90%']"
									data-type="text"
									data-textalign="['left','left','center','center']"
									data-transform_idle="o:1;"
									data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
									data-transform_out="s:300;e:Power2.easeInOut;s:300;e:Power2.easeInOut;"
									data-start={100}
									data-splitin="none"
									data-splitout="none"
									style={{
										zIndex: 3,
										fontWeight: 300,
										color: "#ffffff",
										fontFamily: '"Raleway", sans-serif',
										lineHeight: "1.5 !important",
									}}
								>
									Accelerate your digital transformation with our software
									engineering, data and low code services.
								</div>

								<div
									className="tp-caption tp-resizeme talk-to-us"
									data-x="['left','left','center','center']"
									data-hoffset="['0','0','0','0']"
									data-y="['middle','middle','middle','middle']"
									data-voffset="['100','100','150','150']"
									data-width="['160','160','100%','100%']"
									data-frames='[{"delay":600,"speed":2000,"frame":"0","from":"sX:1;sY:1;opacity:0;fb:40px;","to":"o:1;fb:0;","ease":"Power4.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
									data-textalign="['left','left','center','center']"
									style={{ zIndex: 5, maxWidth: 960 }}
								>
									<a
										href="/contact-us"
										className="btn-setting btn-outline-info rounded text-white  mr-4"
										onClick={() =>
											raiseGAEvent(
												"Button Click",
												"Talk to us - slide 1",
												"Home Page"
											)
										}
									>
										Talk To Us{" "}
									</a>
									<a
										href="/services"
										className="btn-setting btn-scale btn-blue text-white learn-more "
										onClick={() =>
											raiseGAEvent(
												"Button Click",
												"Learn more - slide 1",
												"Home Page"
											)
										}
									>
										Explore{"    "}
										<i
											className="fa fa-arrow-right ml-1"
											aria-hidden="true"
										></i>
									</a>
								</div>
							</div>
						</li>

						{/* SLIDE 2 */}
						<li
							data-index="rs-04"
							data-transition="fade"
							data-slotamount="default"
							data-easein="Power3.easeInOut"
							data-easeout="Power3.easeInOut"
							data-masterspeed={2000}
							data-fsmasterspeed={1500}
							data-param1=""
						>
							<video src={slide2video} autoPlay muted loop playsInline />
							<div className="bg-overlay bg-black opacity-1" />
							<div className="container position-relative h-100">
								<div
									className="tp-caption tp-resizeme"
									data-x="['left','left','center','center']"
									data-hoffset="['-60','-60','0','0']"
									data-y="['middle','middle','middle','middle']"
									data-voffset="['-130','-130','-210','-210']"
									data-fontsize="['80','80','50','50']"
									data-whitespace="['wrap','wrap','wrap','wrap']"
									data-lineheight="100"
									data-responsive_offset="on"
									data-width="['95%','95%','90%','90%']"
									data-type="text"
									data-textalign="['left','left','center','center']"
									data-frames='[{"delay":500,"speed":1500,"frame":"0","from":"x:50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
									data-start={300}
									data-splitin="none"
									data-splitout="none"
									style={{
										zIndex: 1,
										fontWeight: 500,
										lineHeight: 60,
										color: "#ffffff",
										fontFamily: '"Raleway", sans-serif',
									}}
								>
									Business transformation with real-time data
								</div>

								<div
									className="tp-caption tp-resizeme"
									data-x="['left','left','center','center']"
									data-hoffset="['-60','-60','0','0']"
									data-y="['middle','middle','middle','middle']"
									data-voffset="['35','35','5','15']"
									data-fontsize="['30','30','25','25']"
									data-lineheight="40"
									data-whitespace="['wrap','wrap','wrap','wrap']"
									data-responsive_offset="on"
									data-width="['90%','90%','90%','90%']"
									data-type="text"
									data-textalign="['left','left','center','center']"
									data-frames='[{"delay":200,"speed":1500,"frame":"0","from":"x:[100%];opacity:1;","mask":"x:[-100%];y:0;s:inherit;e:inherit;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"x:[100%];opacity:1;","mask":"x:[-100%];y:0;s:inherit;e:inherit;","ease":"Power4.easeInOut"}]'
									data-start={200}
									data-splitin="none"
									data-splitout="none"
									style={{
										zIndex: 1,
										fontWeight: 300,
										lineHeight: 60,
										color: "#ffffff",
										fontFamily: '"Raleway", sans-serif',
									}}
								>
									Build massively scalable, high-performance applications and
									business intelligence solutions with Aerospike on AWS
								</div>

								<div
									className="tp-caption tp-resizeme talk-to-us"
									data-x="['left','left','center','center']"
									data-hoffset="['-50','-50','0','0']"
									data-y="['middle','middle','middle','middle']"
									data-voffset="['145','145','150','150']"
									data-width="['180','180','100%','100%']"
									data-frames='[{"delay":200,"speed":2000,"frame":"0","from":"sX:1;sY:1;opacity:0;fb:40px;","to":"o:1;fb:0;","ease":"Power4.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
									data-textalign="['left','left','center','center']"
									style={{ zIndex: 99, maxWidth: 960 }}
								>
									<a
										href="/services"
										className="btn-setting btn-scale btn-blue text-white learn-more "
										onClick={() =>
											raiseGAEvent(
												"Button Click",
												"Learn more - slide 2",
												"Home Page"
											)
										}
									>
										Learn more
										<i
											className="fa fa-arrow-right ml-2"
											aria-hidden="true"
										></i>
									</a>
								</div>
							</div>
						</li>
						{/* SLIDE 3 */}
						<li
							data-index="rs-03"
							data-transition="fade"
							data-slotamount="default"
							data-easein="Power3.easeInOut"
							data-easeout="Power3.easeInOut"
							data-masterspeed={2000}
							data-fsmasterspeed={1500}
							data-param1=""
						>
							{/* MAIN IMAGE */}
							<img
								src={banner3}
								alt=""
								data-bgposition="center center"
								data-bgfit="cover"
								data-bgrepeat="no-repeat"
								className="rev-slidebg"
								data-bgparallax={0}
								data-no-retina=""
							/>

							<div className="bg-overlay bg-black opacity-6" />
							<div className="container position-relative h-100">
								<div
									className="tp-caption tp-resizeme"
									data-x="['left','left','center','center']"
									data-hoffset="['-60','-60','0','0']"
									data-y="['middle','middle','middle','middle']"
									data-voffset="['-130','-130','-250','-250']"
									data-fontsize="['80','80','45','45']"
									data-whitespace="['wrap','wrap','wrap','wrap']"
									data-lineheight="100"
									data-responsive_offset="on"
									data-width="['100%','100%','90%','90%']"
									data-type="text"
									data-textalign="['left','left','center','center']"
									data-frames='[{"delay":1000,"speed":1500,"frame":"0","from":"x:50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
									data-start={1500}
									data-splitin="none"
									data-splitout="none"
									style={{
										zIndex: 1,
										fontWeight: 500,
										lineHeight: 60,
										color: "#ffffff",
										fontFamily: '"Raleway", sans-serif',
									}}
								>
									Application Modernization using Low Code Platforms
								</div>

								<div
									className="tp-caption tp-resizeme slide3 d-flex align-items-center w-100 flex-column flex-md-row justify-content-center justify-content-md-start"
									data-x="['left','left','center','center']"
									data-hoffset="['-60','-60','0','0']"
									data-y="['middle','middle','middle','middle']"
									data-voffset="['15,'15','-20','-20']"
									data-fontsize="['25','25','25','25']"
									data-whitespace="['wrap','wrap','wrap','wrap']"
									data-lineheight="40"
									data-responsive_offset="on"
									data-width="['100%','100%','90%','90%']"
									data-type="text"
									data-textalign="['left','left','center','center']"
									data-frames='[{"delay":1500,"speed":1500,"frame":"0","from":"x:[100%];opacity:1;","mask":"x:[-100%];y:0;s:inherit;e:inherit;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"x:[100%];opacity:1;","mask":"x:[-100%];y:0;s:inherit;e:inherit;","ease":"Power4.easeInOut"}]'
									data-start={2000}
									data-splitin="none"
									data-splitout="none"
									style={{
										zIndex: 1,
										fontWeight: 300,
										lineHeight: 60,
										color: "#ffffff",
										fontFamily: '"Raleway", sans-serif',
									}}
								>
									<span>
										Accelerate your digital transformation & innovation with
										low-code platforms.
									</span>
									<div className="container logos d-flex align-items-center p-0 w-100 justify-content-center justify-content-md-start">
										<img src={mendix} alt="" width={80} height={80} />
										<img src={p1} alt="" width={80} height={80} />
									</div>
								</div>
								
								<div
									className="tp-caption tp-resizeme talk-to-us"
									data-x="['left','left','center','center']"
									data-hoffset="['-50','-50','0','0']"
									data-y="['middle','middle','middle','middle']"
									data-voffset="['145','145','150','150']"
									data-width="['180','180','100%','100%']"
									data-frames='[{"delay":200,"speed":2000,"frame":"0","from":"sX:1;sY:1;opacity:0;fb:40px;","to":"o:1;fb:0;","ease":"Power4.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
									data-textalign="['left','left','center','center']"
									style={{ zIndex: 99, maxWidth: 960 }}
								>
									<a
										href="/services"
										className="btn-setting btn-scale btn-blue text-white learn-more "
										onClick={() =>
											raiseGAEvent(
												"Button Click",
												"Learn more - slide 3",
												"Home Page"
											)
										}
									>
										Learn more
										<i
											className="fa fa-arrow-right ml-2"
											aria-hidden="true"
										></i>
									</a>
								</div>
							</div>
						</li>
						{/* SLIDE 4 */}
						<li
							data-index="rs-02"
							data-transition="fade"
							data-slotamount="default"
							data-easein="Power3.easeInOut"
							data-easeout="Power3.easeInOut"
							data-masterspeed={2000}
							data-fsmasterspeed={1500}
							data-param1=""
						>
							<video src={slide3video} autoPlay muted loop playsInline />

							<div className="bg-overlay bg-black opacity-5" />
							<div className="container position-relative h-100">
								<div
									className="tp-caption tp-resizeme"
									data-x="['left','left','center','center']"
									data-hoffset="['0','0','0','0']"
									data-y="['middle','middle','middle','middle']"
									data-voffset="['-130','-130','-210','-210']"
									data-fontsize="['80','80','50','50']"
									data-whitespace="['wrap','wrap','wrap','wrap']"
									data-responsive_offset="on"
									data-width="['100%','100%','90%','90%']"
									data-type="text"
									data-textalign="['left','left','center','center']"
									data-frames='[{"delay":500,"speed":1500,"frame":"0","from":"x:50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
									data-start={500}
									data-splitin="none"
									data-splitout="none"
									style={{
										zIndex: 1,
										fontWeight: 500,
										lineHeight: 60,
										color: "#ffffff",
										fontFamily: '"Raleway", sans-serif',
									}}
								>
									Digital Product Engineering
								</div>
								<div
									className="tp-caption tp-resizeme"
									data-x="['left','left','center','center']"
									data-hoffset="['-60','-60','0','0']"
									data-y="['middle','middle','middle','middle']"
									data-voffset="['-45','-45','-85','-85']"
									data-fontsize="['30','30','25','25']"
									data-whitespace="['wrap','wrap','wrap','wrap']"
									data-responsive_offset="on"
									data-width="['100%','100%','90%','90%']"
									data-type="text"
									data-textalign="['left','left','center','center']"
									data-frames='[{"delay":200,"speed":1500,"frame":"0","from":"x:[100%];opacity:1;","mask":"x:[-100%];y:0;s:inherit;e:inherit;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"x:[100%];opacity:1;","mask":"x:[-100%];y:0;s:inherit;e:inherit;","ease":"Power4.easeInOut"}]'
									data-start={200}
									data-splitin="none"
									data-splitout="none"
									style={{
										zIndex: 1,
										fontWeight: 300,
										lineHeight: 60,
										color: "#ffffff",
										fontFamily: '"Raleway", sans-serif',
									}}
								>
									Build and launch new products quickly and cost effectively.
								</div>
								<div
									className="tp-caption tp-resizeme talk-to-us"
									data-x="['left','left','center','center']"
									data-hoffset="['-50','-50','0','0']"
									data-y="['middle','middle','middle','middle']"
									data-voffset="['50','50','150','150']"
									data-width="['180','180','100%','100%']"
									data-frames='[{"delay":200,"speed":2000,"frame":"0","from":"sX:1;sY:1;opacity:0;fb:40px;","to":"o:1;fb:0;","ease":"Power4.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
									data-textalign="['left','left','center','center']"
									style={{ zIndex: 99, maxWidth: 960 }}
								>
									<a
										href="/labs"
										className="btn-setting btn-scale btn-blue text-white learn-more "
										onClick={() =>
											raiseGAEvent(
												"Button Click",
												"Learn more - slide 4",
												"Home Page"
											)
										}
									>
										Learn More
										<i
											className="fa fa-arrow-right ml-2"
											aria-hidden="true"
										></i>
									</a>
								</div>
							</div>
						</li>
						{/* slide 5 */}
						<li
							data-index="rs-05"
							data-transition="fade"
							data-slotamount="default"
							data-easein="Power3.easeInOut"
							data-easeout="Power3.easeInOut"
							data-masterspeed={2000}
							data-fsmasterspeed={1500}
							data-param1=""
						>
							<video src={slide5video} autoPlay muted loop playsInline />

							<div className="bg-overlay bg-black opacity-5" />
							<div className="container position-relative h-100">
								<div
									className="tp-caption tp-resizeme methodical"
									data-x="['left','left','center','center']"
									data-hoffset="['-60','-60','0','0']"
									data-y="['middle','middle','middle','middle']"
									data-voffset="['-190','-190','-280','-280']"
									data-fontsize="['60','60','20','20']"
									data-whitespace="['nowrap','nowrap','wrap','wrap']"
									data-responsive_offset="on"
									data-width="['none','none','90%','90%']"
									data-type="image"
									data-textalign="['center','center','center','center']"
									data-transform_idle="o:1;"
									data-transform_in="x:-50px;opacity:0;s:2000;e:Power3.easeOut;"
									data-transform_out="s:300;e:Power3.easeInOut;s:300;e:Power3.easeInOut;"
									data-start={1000}
									data-splitin="none"
									data-splitout="none"
									style={{
										zIndex: 1,
										fontWeight: 300,
										color: "#ffffff",
										fontFamily: '"Raleway", sans-serif',
										textTransform: "capitalize",
									}}
								>
									<div className="d-flex align-items-center methodical justify-content-center justify-content-md-start">
										<img src={methodical} alt="" width={30} height={30} />
										<h4 className="ml-2">Methodical</h4>
									</div>
								</div>
								<div
									className="tp-caption tp-resizeme"
									data-x="['left','left','center','center']"
									data-hoffset="['0','0','0','0']"
									data-y="['middle','middle','middle','middle']"
									data-voffset="['-80','-80','-150','-150']"
									data-fontsize="['60','60','50','50']"
									data-whitespace="['wrap','wrap','wrap','wrap']"
									data-responsive_offset="on"
									data-width="['100%','100%','94%','94%']"
									data-type="text"
									data-textalign="['left','left','center','center']"
									data-transform_idle="o:1;"
									data-transform_in="z:0;rX:0;rY:0;rZ:0;sX:0.9;sY:0.9;skX:0;skY:0;opacity:0;s:1500;e:Power3.easeInOut;"
									data-transform_out="y:[100%];s:300;e:Power2.easeInOut;s:300;e:Power2.easeInOut;"
									data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
									data-start={1200}
									data-splitin="none"
									data-splitout="none"
									style={{
										zIndex: 2,
										fontWeight: 500,
										color: "#ffffff",
										fontFamily: '"Raleway", sans-serif',
									}}
								>
									Build and deploy enterprise tools quickly
								</div>

								<div
									className="tp-caption tp-resizeme"
									data-x="['left','left','center','center']"
									data-hoffset="['-60','-60','0','0']"
									data-y="['middle','middle','middle','middle']"
									data-voffset="['5','5','15','15']"
									data-fontsize="['30','30','25','25']"
									data-lineheight="40"
									data-whitespace="['wrap','wrap','wrap','wrap']"
									data-responsive_offset="on"
									data-width="['95%','95%','90%','90%']"
									data-type="text"
									data-textalign="['left','left','center','center']"
									data-transform_idle="o:1;"
									data-transform_in="z:0;rX:0deg;rY:0;rZ:0;sX:2;sY:2;skX:0;skY:0;opacity:0;s:1000;e:Power2.easeOut;"
									data-transform_out="s:300;e:Power3.easeInOut;s:300;e:Power3.easeInOut;"
									data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
									data-start={1500}
									data-splitin="none"
									data-splitout="none"
									style={{
										zIndex: 4,
										fontWeight: 200,
										color: "#ffffff",
										lineHeight: 30,
										fontFamily: '"Raleway", sans-serif',
									}}
								>
									Methodical - an all-in-one Workflow, Data and AI platform for
									the modern digital enterprise.
								</div>

								<div
									className="tp-caption tp-resizeme talk-to-us"
									data-x="['left','left','center','center']"
									data-hoffset="['0','0','0','0']"
									data-y="['middle','middle','middle','middle']"
									data-voffset="['100','100','150','150']"
									data-width="['160','160','100%','100%']"
									data-frames='[{"delay":600,"speed":2000,"frame":"0","from":"sX:1;sY:1;opacity:0;fb:40px;","to":"o:1;fb:0;","ease":"Power4.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
									data-textalign="['left','left','center','center']"
									style={{ zIndex: 5, maxWidth: 960 }}
								>
									<a
										href="/contact-us"
										className="btn-setting btn-outline-info rounded text-white  mr-4"
										onClick={() =>
											raiseGAEvent(
												"Button Click",
												"Request Demo - slide 5",
												"Home Page"
											)
										}
									>
										Request Demo{" "}
									</a>
									<a
										href="/labs"
										className="btn-setting btn-scale btn-blue text-white learn-more"
										onClick={() =>
											raiseGAEvent(
												"Button Click",
												"Learn more - slide 5",
												"Home Page"
											)
										}
									>
										learn more
										<i
											className="fa fa-arrow-right ml-2"
											aria-hidden="true"
										></i>
									</a>
								</div>
							</div>
						</li>
						{/* Tech partners starts */}
						<div className="tech-partners p-4 mt-2">
							<div className="container">
								<h4>Our Technology Partners</h4>
								<div className="logos justify-content-start justify-content-md-start">
									<img src={mendix} alt="" width={80} height={80} />
									<img src={aerospike} alt="" width={100} height={100} />
									<img src={aws} alt="" width={100} className="aws" />
									<img src={micro} alt="" width={100} height={100} />
								</div>
							</div>
						</div>
						{/* Tech Partners ends */}
					</ul>
				</div>
			</div>
		</section>
	);
};

export default HomeBanner;
