import React from "react";
import { raiseGAEvent } from "../../Events/Event";
const CareersRequest = (props) => {
	return (
		<section id="request" className="careers service-contact">
			<div className="bg-overlay bg-black opacity-5" />

			<div className="container ">
				<div className="row">
					<div className="col-lg-12 col-md-12 split-box-container-setting  p-md-0 px-md-0">
						<div
							className="main-title wow fadeIn style-two text-left "
							data-wow-delay="300ms"
						>
							<h2 className="heading mb-3">
								We're always looking for exceptional people
							</h2>
							<p className="mb-5">
								Explore open positions at RedBlackTree or simply have a chat
								with us.
							</p>
							<div className="talk-to-us mb-0 mt-5 flex-column flex-md-row align-items-center">
								<a
									href="https://angel.co/redblacktree/jobs"
									target="_blank"
									rel="noreferrer"
									className=" btn btn-setting rounded text-white wow zoomIn mr-md-5 job-btn "
									data-wow-delay=".8s"
									onClick={() =>
										raiseGAEvent(
											"Button Click",
											"Jobs at RBT - bottom",
											"Careers Page"
										)
									}
								>
									Jobs at RedBlackTree{" "}
									<i className="fa fa-arrow-right ml-1" aria-hidden="true"></i>
								</a>
								<a
									href="/contact-us"
									className=" btn btn-setting btn-outline-info rounded text-white wow zoomIn mt-3 mt-md-0"
									data-wow-delay=".8s"
								>
									Get in Touch
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default CareersRequest;
