import React, { useEffect, useState } from "react";
import "../components/Styles/resources.css";
import { Footer } from "../components/footer";
import { Header } from "../components/Header/header";
import "react-tabs/style/react-tabs.css";
import { getBlogMediaById, getBlogs, getCategories, getTags } from "../api";

export const Resources = () => {
	const [blogPosts, setBlogPosts] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [postsPerPage] = useState(4);
	const [searchQuery, setSearchQuery] = useState("");
	const [ourBlogs, setOurBlogs] = useState([]);
	const [allCategories, setAllCategories] = useState([]);
	const [artcleId, setArticleId] = useState("");
	const [blogs, setBlogs] = useState([]);
	const [caseStudies, setCaseStudies] = useState([]);
	const [tags, setTags] = useState([]);
	const [selectedTagIds, setSelectedTagIds] = useState([]);
	const [originalBlogs, setOriginalBlogs] = useState([]);
	const [caseStudyCategoryId, setCaseStudyCategoryId] = useState("");
	useEffect(() => {
		getOurBlogs();

		getAllTags();
	}, []);
	useEffect(() => {
		getBlogCategories();
	}, [ourBlogs]);
	const getOurBlogs = async () => {
		const blogs = await getBlogs();
		if (Array.isArray(blogs?.data) && blogs?.data?.length > 0) {
			const updatedBlogs = await Promise.all(
				blogs.data.map(async (blog) => {
					const imageUrl = await getBlogMediaById(blog?.featured_media);
					return { ...blog, imageUrl };
				})
			);
			setOurBlogs(updatedBlogs);
			setOriginalBlogs(updatedBlogs);
		}
	};

	useEffect(() => {
		getBlogsByCategory();
	}, [allCategories]);

	const getBlogCategories = async () => {
		const categories = await getCategories();
		setAllCategories(categories);
	};

	const getBlogsByCategory = async () => {
		const ArticleCategoryId = findCategoryId("Article");
		const categoryBlogs = await getPostsByCategory(ArticleCategoryId);
		// get latest 3 blogs
		if (Array.isArray(categoryBlogs) && categoryBlogs.length > 0) {
			setBlogs(categoryBlogs);
		} else {
			setBlogs([]);
		}
		// setCaseStudies(getPostsByCategory(caseStudyCategoryId));
	};
	// funtion to find id of the category by name
	const findCategoryId = (name) => {
		if (Array.isArray(allCategories) && allCategories.length > 0) {
			const category = allCategories?.find(
				(category) => category.name === name
			);
			return category?.id;
		} else {
			return null;
		}
	};

	// function to get posts by article id
	const getPostsByCategory = (id) => {
		const posts = ourBlogs.filter((blog) => blog.categories.includes(id));
		return posts;
	};

	// function to get all tags
	const getAllTags = async () => {
		const tags = await getTags();
		setTags(tags);
	};

	const tagOnClick = (id) => {
		// check if the tag is already selected
		if (selectedTagIds.includes(id)) {
			const filteredTags = selectedTagIds.filter((tagId) => tagId !== id);
			setSelectedTagIds(filteredTags);
			if (filteredTags.length > 0) {
				// filter the posts based on the selected tags

				const posts = ourBlogs.filter((blog) => {
					return blog.tags.some((tag) => filteredTags.includes(tag));
				});

				setOurBlogs(posts);
			} else {
				setOurBlogs(originalBlogs);
				return;
			}
			return;
		} else {
			// add the tag to the selected tags
			setSelectedTagIds([...selectedTagIds, id]);
			const posts = ourBlogs.filter((blog) => blog.tags.includes(id));
			setOurBlogs(posts);
		}
	};

	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const currentPosts = blogPosts.slice(indexOfFirstPost, indexOfLastPost);
	const paginate = (pageNumber) => setCurrentPage(pageNumber);
	const previousPage = () =>
		currentPage !== 1 && setCurrentPage(currentPage - 1);
	const nextPage = () =>
		currentPage !== Math.ceil(blogPosts.length / postsPerPage) &&
		setCurrentPage(currentPage + 1);
	const handleSearch = () => {
		const filtered = blogPosts.filter((post) =>
			post.name.toLowerCase().includes(searchQuery)
		);
	};
	return (
		<div>
			<>
				{/* Header start */}
				<Header />
				{/* Header end */}
				{/* Main Section start */}
				<section id="resources-home">
					<div className="bg-overlay bg-black opacity-5" />
					<div className="container d-flex align-items-center justify-content-center text-left h-100">
						<div
							className="col-12 col-md-12 px-0 section-heading about-fadeIn wow"
							data-wow-delay=".5s"
						>
							<div className="mb-4 mb-md-0">
								<h2
									className="heading wow fadeIn text-white font-weight-500"
									data-wow-delay=".6s"
								>
									Resources
								</h2>
								<div className="wow zoomIn p-0" data-wow-delay=".8s">
									<h5 className="mb-4 about-p">
										The latest blog articles, news, events and updates from
										RedBlackTree.{" "}
									</h5>
								</div>
								&nbsp; <br /> &nbsp;
							</div>
						</div>
					</div>
				</section>
				{/* Main Section end */}
				{/* tabs start */}
				<section id="tabs">
					<div className="container">
						<div className="row ">
							<div className="col-md-12 ">
								<div
									className="main-title wow fadeIn  text-left style-two mb-3"
									data-wow-delay="300ms"
								>
									<h2 className="heading mb-0">Blog Articles</h2>
								</div>
							</div>
						</div>
						<div className="row w-100 mx-0">
							<div className="blogs-grid resources">
								{blogs &&
									blogs.length > 0 &&
									blogs.map((blog, index) => {
										return (
											<div className="blog" key={index}>
												<a
													href={
														blog?.categories?.includes(10)
															? `/resources/caseStudy/${blog?.id}`
															: `/resources/blog/${blog.id}`
													}
													className="news-item fadeInUp wow pb-3"
													data-wow-delay=".2s"
												>
													<div className="image-holder-gallary">
														<img alt={blog?.name} src={blog.imageUrl} />
														<div className="article-type">
															{blog?.categories?.includes(10)
																? "Case Study"
																: "Blog"}
														</div>
													</div>

													<div className="news-text-box px-3 ">
														<div>
															<h4 className="news-title main-font">
																{blog?.title?.rendered}
															</h4>
														</div>
														<div
															className="para alt-font "
															dangerouslySetInnerHTML={{
																__html: blog?.excerpt?.rendered,
															}}
														></div>
														<div className="author bottom-0 ">
															<div className="author-name">
																<span>
																	{blog?.authors?.[0]?.display_name},{" "}
																	<span className="desc">
																		{blog?.authors?.[0]?.description}
																	</span>
																</span>
																<span className="date d-block ">
																	{new Date(blog?.date).toLocaleDateString(
																		"en-US",
																		{
																			month: "short",
																			day: "numeric",
																			year: "numeric",
																		}
																	)}
																</span>
															</div>
														</div>

														{/* eslint-disable jsx-a11y/anchor-is-valid */}
														<div className="mt-3 font-weight-600 position-absolute bottom-0 learn-more-btn ">
															Read more
															<i
																className="fa fa-arrow-right ml-2"
																aria-hidden="true"
															></i>
														</div>
														<div className="d-flex align-items-center justify-content-between">
															{/* <div className="author">David Williams</div> */}
														</div>
													</div>
												</a>
											</div>
										);
									})}
							</div>
						</div>
					</div>
				</section>
				{/* tabs ended */}
				{/* Footer starts */}
				<Footer />
				{/* Footer ends */}
			</>
		</div>
	);
};
