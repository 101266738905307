import React, { useState } from "react";
import earnIn from "../../Images/earnIn.png";

const ServiceEnterprises = (props) => {
	const [customerlogoShow, setCustomerlogoShow] = useState({
		verizon: false,
		viking: false,
		ir: false,
		saipem: false,
		nus: false,
		schneider: false,
	});
	return (
		<section id="contact" className="bg-light-gray3">
			<div className="">
				<div className="container">
					<div className="row">
						<div className="col-xs-12 col-sm-12">
							<div
								className="main-title wow fadeIn text-left style-two "
								data-wow-delay="300ms"
							>
								<h2 className="heading">You're in Good Company</h2>
								<p className="text-left">
									Trusted by leading global enterprises
								</p>
							</div>
						</div>
					</div>
					<div className="row r_logo" style={{ marginBottom: 0 }}>
						<div className="col-xs-12">
							<div className="row service-partners customerlogos">
								<a
									href="https://www.verizon.com/"
									target="_blank"
									rel="noreferrer"
								>
									<div
										className="verizon customerlogo"
										onMouseEnter={() => {
											setCustomerlogoShow({ verizon: true });
										}}
										onMouseLeave={() => {
											setCustomerlogoShow({ verizon: false });
										}}
									>
										<img
											className=""
											style={{ height: 85 }}
											id="verizonImg"
											src={
												customerlogoShow.verizon
													? `https://rbtwebsite-assets.s3.amazonaws.com/ap-south-1/images/customerlogos/verizon.png`
													: `https://rbtwebsite-assets.s3.amazonaws.com/ap-south-1/images/customerlogos/verizon_color.png `
											}
											alt=""
										/>
										<div id="verizonText" className="text-center subtext">
											<div style={{ fontSize: 18, color: "#fff" }}>Verizon</div>
											<div style={{ fontSize: 14, color: "#fff" }}>
												Inspiring tomorrow’s creators
											</div>
										</div>
									</div>
								</a>
								<a
									href="https://www.earnin.com/"
									target="_blank"
									rel="noreferrer"
								>
									<div
										className="viking customerlogo"
										onMouseEnter={() => {
											setCustomerlogoShow({ viking: true });
										}}
										onMouseLeave={() => {
											setCustomerlogoShow({ viking: false });
										}}
									>
										<img
											className=""
											style={{ height: 110 }}
											id="vikingImg"
											src={customerlogoShow.viking ? earnIn : earnIn}
											alt=""
										/>
										<div id="vikingText " className="text-center  subtext">
											<div style={{ fontSize: 18, color: "#000" }}>EarnIn</div>
											<div style={{ fontSize: 14, color: "#000" }}>
												Exploring the world in comfort
											</div>
										</div>
									</div>
								</a>
								<a
									href="https://company.ingersollrand.com/"
									target="_blank"
									rel="noreferrer"
								>
									{" "}
									{/* link changed on 18th April 2018 */}
									<div
										className="ir customerlogo"
										onMouseEnter={() => {
											setCustomerlogoShow({ ir: true });
										}}
										onMouseLeave={() => {
											setCustomerlogoShow({ ir: false });
										}}
									>
										<img
											className=""
											style={{ height: 85 }}
											id="IRImg"
											src={
												customerlogoShow.ir
													? "https://rbtwebsite-assets.s3.amazonaws.com/ap-south-1/images/customerlogos/ir.png "
													: "https://rbtwebsite-assets.s3.amazonaws.com/ap-south-1/images/customerlogos/ir_color.png "
											}
											alt=""
										/>
										<div id="IRText" className="text-center subtext">
											<div style={{ fontSize: 18, color: "#fff" }}>
												Ingersoll rand
											</div>
											<div style={{ fontSize: 14, color: "#fff" }}>
												Creating comfortable, sustainable and efficient
												environments
											</div>
										</div>
									</div>
								</a>
								<a
									href="http://www.saipem.com"
									target="_blank"
									rel="noreferrer"
								>
									<div
										className="saipem customerlogo"
										onMouseEnter={() => {
											setCustomerlogoShow({ saipem: true });
										}}
										onMouseLeave={() => {
											setCustomerlogoShow({ saipem: false });
										}}
									>
										<img
											className=""
											id="saipemImg"
											style={{ height: 85 }}
											src={
												customerlogoShow.saipem
													? "https://rbtwebsite-assets.s3.amazonaws.com/ap-south-1/images/customerlogos/saipem.png "
													: "https://rbtwebsite-assets.s3.amazonaws.com/ap-south-1/images/customerlogos/saipem_color.png "
											}
											alt="logo"
										/>
										<div id="saipemtext" className="text-center subtext">
											<div style={{ fontSize: 18, color: "#fff" }}>Saipem</div>
											<div style={{ fontSize: 14, color: "#fff" }}>
												Engineering energy
											</div>
										</div>
									</div>
								</a>
								<a
									href="http://www.nus.edu.sg/"
									target="_blank"
									rel="noreferrer"
								>
									<div
										className="NUS customerlogo"
										onMouseEnter={() => {
											setCustomerlogoShow({ nus: true });
										}}
										onMouseLeave={() => {
											setCustomerlogoShow({ nus: false });
										}}
									>
										<img
											className=""
											style={{ height: 85 }}
											id="NUSImg"
											src={
												customerlogoShow.nus
													? "https://rbtwebsite-assets.s3.amazonaws.com/ap-south-1/images/customerlogos/nus.png "
													: "https://rbtwebsite-assets.s3.amazonaws.com/ap-south-1/images/customerlogos/nus_color.png "
											}
											alt="logo"
										/>
										<div id="NUStext" className="text-center subtext">
											<div style={{ fontSize: 18, color: "#fff" }}>NUS</div>
											<div style={{ fontSize: 14, color: "#fff" }}>
												Global university shaping the future
											</div>
										</div>
									</div>
								</a>
								<a
									href="https://www.schneider-electric.com/ww/en/"
									target="_blank"
									rel="noreferrer"
								>
									<div
										className=" schneider customerlogo"
										onMouseEnter={() => {
											setCustomerlogoShow({ schneider: true });
										}}
										onMouseLeave={() => {
											setCustomerlogoShow({ schneider: false });
										}}
									>
										<img
											className=""
											style={{ height: 85 }}
											id="schneiderImg"
											src={
												customerlogoShow.schneider
													? "https://rbtwebsite-assets.s3.amazonaws.com/ap-south-1/images/customerlogos/schneiderelectric.png "
													: "https://rbtwebsite-assets.s3.amazonaws.com/ap-south-1/images/customerlogos/schneiderelectric_color.png "
											}
											alt="logo"
										/>
										<div id="schneidertext" className="text-center subtext">
											<div style={{ fontSize: 18, color: "#fff" }}>
												Schneider Electric
											</div>
											<div style={{ fontSize: 14, color: "#fff" }}>
												Global specialist in energy management and automation
											</div>
										</div>
									</div>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ServiceEnterprises;
