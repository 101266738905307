import axios from "axios";
import {
	PostsUrl,
	getBlogMediaUrl,
	getCategoriesUrl,
	getTagsUrl,
} from "./Config/Constants";

// const url = "http://localhost:5000/api/v1/contact/create";
// const url = "https://website-api.redblacktree.net/api/v1/contact/create";
const url = "https://api.redblacktree.com/api/v1/contact/create";

export const createContact = async (data) => {
	try {
		let resp = await axios.post(url, data);
		return resp;
	} catch (e) {
		return e;
	}
};

export const getBlogs = async () => {
	try {
		let resp = await axios.get(PostsUrl);
		return resp;
	} catch (error) {
		return error;
	}
};
export const getBlogMediaById = async (id) => {
	try {
		let resp = await axios.get(`${getBlogMediaUrl}/${id}`);
		if (resp?.status === 200) {
			return resp?.data?.source_url;
		}
	} catch (error) {
		return error;
	}
};
export const getComments = async (url) => {
	try {
		let resp = await axios.get(url);
		if (resp?.status === 200) {
			return resp?.data;
		}
	} catch (error) {
		return error;
	}
};
export const getCategories = async () => {
	try {
		let resp = await axios.get(getCategoriesUrl);
		if (resp?.status === 200) {
			return resp?.data;
		}
	} catch (error) {
		return error;
	}
};
export const getPostTags = async (id) => {
	try {
		const postTagUrl = `${getTagsUrl}?post=${id}`;
		let resp = await axios.get(postTagUrl);
		if (resp?.status === 200) {
			return resp?.data;
		}
	} catch (error) {
		return error;
	}
};
export const getTags = async () => {
	try {
		let resp = await axios.get(getTagsUrl);
		if (resp?.status === 200) {
			return resp?.data;
		}
	} catch (error) {
		return error;
	}
};
