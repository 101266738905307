import React from "react";

const HomeAbout = (props) => {
	return (
		<section id="aboutus" className="bg-light-gray3 about">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-12">
						<div
							className="main-title style-two text-left text-md-left wow fadeIn col-12 col-md-12 mb-0"
							data-wow-delay=".6s"
						>
							<div className="">
								<h2 className="heading wow fadeIn "> What we do </h2>
								<div className="wow zoomIn" data-wow-delay=".8s">
									<span className="about-p text-dark home">
										We are a technology services firm with 2 decades of
										experience across a range of industry verticals and
										geographies. Our constant focus on disciplined execution and
										rigourous software engineering practices have helped us
										build a strong track record of delivery excellence over the
										years. Our unique mix of skills with low latency, real-time
										data and back-end systems, low code platforms and emerging
										AI technologies sets us apart and makes us a valuable
										partner for our customers in their digital transformation
										journeys.
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default HomeAbout;
