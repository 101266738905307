import React, { useState, useEffect } from "react";
import { Footer } from "../components/footer";
import { Header } from "../components/Header/header";
import "../components/Styles/home.css";
import CaseComponent from "../components/Common/CaseComponent";
import BlogComponent from "../components/Common/BlogComponent";
import HomeBanner from "../components/HomeComponents/HomeBanner";
import HomeAbout from "../components/HomeComponents/HomeAbout";
import HomeServices from "../components/HomeComponents/HomeServices";
import HomeOurExpertise from "../components/HomeComponents/HomeOurExpertise";
import HomeValues from "../components/HomeComponents/HomeValues";
import TestimonialComponent from "../components/Common/TestimonialComponent";
import SocialMedia from "../components/HomeComponents/SocialMedia";
import RequestComponent from "../components/Common/RequestComponent";
import { getBlogMediaById, getBlogs, getCategories } from "../api";

export const Home = () => {
	const [ourBlogs, setOurBlogs] = useState([]);
	const [blogs, setBlogs] = useState([]);
	const [allCategories, setAllCategories] = useState([]);

	useEffect(() => {
		getOurBlogs();
	}, []);
	useEffect(() => {
		getBlogCategories();
	}, [ourBlogs]);
	const getOurBlogs = async () => {
		const blogs = await getBlogs();
		if (Array.isArray(blogs?.data) && blogs?.data?.length > 0) {
			const updatedBlogs = await Promise.all(
				blogs.data.map(async (blog) => {
					const imageUrl = await getBlogMediaById(blog?.featured_media);
					return { ...blog, imageUrl };
				})
			);
			setOurBlogs(updatedBlogs);
		}
	};

	useEffect(() => {
		getBlogsByCategory();
	}, [allCategories]);

	const getBlogCategories = async () => {
		const categories = await getCategories();
		setAllCategories(categories);
	};

	const getBlogsByCategory = async () => {
		const ArticleCategoryId = findCategoryId("Article");
		const categoryBlogs = await getPostsByCategory(ArticleCategoryId);
		// get latest 3 blogs
		if (Array.isArray(categoryBlogs) && categoryBlogs.length > 0) {
			const latestBlogs = categoryBlogs?.slice(0, 3);
			setBlogs(latestBlogs);
		} else {
			setBlogs([]);
		}
		// setCaseStudies(getPostsByCategory(caseStudyCategoryId));
	};
	// funtion to find id of the category by name
	const findCategoryId = (name) => {
		if (Array.isArray(allCategories) && allCategories.length > 0) {
			const category = allCategories?.find(
				(category) => category.name === name
			);
			return category?.id;
		} else {
			return null;
		}
	};

	// function to get posts by article id
	const getPostsByCategory = (id) => {
		if (Array.isArray(ourBlogs) && ourBlogs.length > 0) {
			const posts = ourBlogs?.filter((blog) => blog.categories.includes(id));
			return posts;
		} else {
			return null;
		}
	};
	return (
		<div>
			<>
				<Header />
				<HomeBanner />
				<HomeAbout />
				{/* <CaseComponent
					heading="Case Studies"
					description="See how RedBlackTree is helping meet the ever-changing needs of
					our customers. Read client case studies, business success
					stories and get behind-the-scenes access to our people,
					engineering teams and their work."
					caseStudies={caseStudies}
				/> */}
				<BlogComponent
					heading="Featured Insights"
					description="Explore our blog featuring the latest updates, articles and case
				studies from our Engineering, People Ops and other teams."
					blogs={blogs}
				/>
				<HomeServices />
				<HomeOurExpertise />
				<HomeValues />
				{/* <TestimonialComponent /> */}
				<SocialMedia />
				<RequestComponent
					parentClassName="service-contact mt-0"
					heading="Use Generative AI to transform business processes!"
					description="Schedule a call with our technical experts."
					eventPageName="Home Page"
				/>
				<Footer />
			</>
		</div>
	);
};
