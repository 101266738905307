import React from "react";
import { raiseGAEvent } from "../../Events/Event";
const RequestComponent = (props) => {
	return (
		<section id="request" className={props?.parentClassName}>
			<div className="bg-overlay bg-black  opacity-5" />

			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-12 col-md-12 split-box-container-setting text-center p-md-0">
						<div
							className="main-title wow fadeIn style-two "
							data-wow-delay="300ms"
						>
							<h2 className="heading mb-3">{props?.heading}</h2>
							<p className="mb-5">{props?.description}</p>
							<div className="talk-to-us mb-0 mt-2">
								<a
									href="/contact-us"
									className=" btn btn-setting btn-outline-info rounded text-white wow zoomIn"
									data-wow-delay=".8s"
									onClick={() =>
										raiseGAEvent(
											"Button Click",
											"Talk to us - Last section",
											`${props?.eventPageName}`
										)
									}
								>
									Talk To Us{" "}
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default RequestComponent;
