import React, { useEffect, useState } from "react";
import "./Popup.css"; // Import your custom styles
import { createContact } from "../../api";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

const Popup = (props) => {
	const [data, setData] = useState({
		firstName: "",
		lastName: "",
		phone: "",
		email: "",
		message: "",
		isConsentToMarketingCommunication: false,
		isConsentToPersonalDataProcessing: false,
	});
	const [industryval, setIndustryval] = useState("");
	const [error, setError] = useState("");
	const [show, setShow] = useState(false);

	useEffect(() => {
		var x, i, j, l, ll, selElmnt, a, b, c;
		/*look for any elements with the class "custom-select-pop":*/
		x = document.getElementsByClassName("custom-select-pop");
		l = x.length;
		for (i = 0; i < l; i++) {
			selElmnt = x[i].getElementsByTagName("select")[0];
			ll = selElmnt.length;
			/*for each element, create a new DIV that will act as the selected item:*/
			a = document.createElement("DIV");
			a.setAttribute("class", "select-selected");
			a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
			x[i].appendChild(a);
			/*for each element, create a new DIV that will contain the option list:*/
			b = document.createElement("DIV");
			b.setAttribute("class", "select-items select-hide");
			for (j = 1; j < ll; j++) {
				/*for each option in the original select element,
    create a new DIV that will act as an option item:*/
				c = document.createElement("DIV");
				c.innerHTML = selElmnt.options[j].innerHTML;
				c.addEventListener("click", function () {
					/*when an item is clicked, update the original select box,
        and the selected item:*/
					var y, i, k, s, h, sl, yl;
					s = this.parentNode.parentNode.getElementsByTagName("select")[0];
					sl = s.length;
					h = this.parentNode.previousSibling;
					for (i = 0; i < sl; i++) {
						if (s.options[i].innerHTML === this.innerHTML) {
							s.selectedIndex = i;
							h.innerHTML = this.innerHTML;
							y = this.parentNode.getElementsByClassName("same-as-selected");
							yl = y.length;
							for (k = 0; k < yl; k++) {
								y[k].removeAttribute("class");
							}
							this.setAttribute("class", "same-as-selected");
							console.log(this.textContent);
							setIndustryval(this.textContent);
							break;
						}
					}
					h.click();
				});
				b.appendChild(c);
			}
			x[i].appendChild(b);
			a.addEventListener("click", function (e) {
				/*when the select box is clicked, close any other select boxes,
      and open/close the current select box:*/
				e.stopPropagation();
				closeAllSelect(this);
				this.nextSibling.classList.toggle("select-hide");
				this.classList.toggle("select-arrow-active");
			});
		}
		function closeAllSelect(elmnt) {
			/*a function that will close all select boxes in the document,
  except the current select box:*/
			var x,
				y,
				i,
				xl,
				yl,
				arrNo = [];
			x = document.getElementsByClassName("select-items");
			y = document.getElementsByClassName("select-selected");
			xl = x.length;
			yl = y.length;
			for (i = 0; i < yl; i++) {
				if (elmnt === y[i]) {
					arrNo.push(i);
				} else {
					y[i].classList.remove("select-arrow-active");
				}
			}
			for (i = 0; i < xl; i++) {
				if (arrNo.indexOf(i)) {
					x[i].classList.add("select-hide");
				}
			}
		}
		/*if the user clicks anywhere outside the select box,
then close all select boxes:*/
		document.addEventListener("click", closeAllSelect);
	}, []);

	const isWorkEmail = (email) => {
		const domain = email.split('@')[1];
		const publicDomains = ['gmail.com', 'yahoo.com', 'hotmail.com', 'aol.com', 'outlook.com'];
		return !publicDomains.includes(domain);
	  }
	const handleChange = (e) => {
		if (e.target.name === "firstName") {
			setData({
				...data,
				firstName: e.target.value,
			});
		}
		if (e.target.name === "lastName") {
			setData({
				...data,
				lastName: e.target.value,
			});
		}
		if (e.target.name === "userPhone") {
			setData({
				...data,
				phone: e.target.value,
			});
		}
		if (e.target.name === "userEmail") {
			setError('')
			setData({
				...data,
				email: e.target.value,
			});
		}
		if (e.target.name === "userMessage") {
			setData({
				...data,
				message: e.target.value,
			});
		}
		if (e.target.name === "consent-checkbox") {
			setData({
				...data,
				isConsentToPersonalDataProcessing: e.target.checked,
			});
		}
		if (e.target.name === "consent-2-checkbox") {
			setData({
				...data,
				isConsentToMarketingCommunication: e.target.checked,
			});
		}
	};
	
	const handleSubmit = async () => {
		ReactGA.event({
			category: "Button Click",
			action: "Clicked on Submit Button on Contact Us Page",
			label: "Contact us Page",
		});

		if (
			data.firstName &&
			data.lastName &&
			data.phone &&
			data.email &&
			data.message
		) {
			const isEmailValid = isWorkEmail(data.email)
			if(!isEmailValid){
				setError("Please enter work email only");
				return;
			}
			const resp = await createContact({ ...data, industry: industryval });
			if (resp.status === 200) {
				setData({
					firstName: "",
					lastName: "",
					phone: "",
					email: "",
					message: "",
					isConsentToMarketingCommunication: false,
					isConsentToPersonalDataProcessing: false,
				});
				setIndustryval("");
				setShow(true);
				setTimeout(() => {
					onClose();
				}, 3000);
			}
		} else {
			setError("Please fill all the fields*");
		}
	};

	const navigate = useNavigate();
	const onClose = () => {
		navigate(-1);
	};

	return (
		<div className="popup-overlay">
			<div className="popup-content">
				<div className="container h-100">
					<div className="row h-100 ">
						<div
							className={
								show
									? "col-lg-5 col-12 pt-5 hide-section d-none position-relative"
									: "col-lg-5 col-12 pt-5 hide-section  position-relative"
							}
						>
							<button className="close-btn mb-3" onClick={onClose}>
								<i class="fas fa-chevron-left"></i> Back
							</button>
							<div className="card px-5 py-4">
								<div
									className="main-title wow fadeIn text-left style-two mb-3 text-white "
									data-wow-delay="300ms"
								>
									<h2 className="heading">Let's have a chat!</h2>
									<p className="text-left">
										Tell us little bit about yourself and what you are looking
										for.
									</p>
								</div>
								<div className="col-12 " id="result" />
								<div className="col-12 p-0 ">
									<div className="d-flex align-items-center justify-content-between flex-column flex-md-row">
										<input
											type="text"
											name="firstName"
											id="firstName"
											placeholder="First Name"
											className="form-control mr-lg-3"
											autoComplete="off"
											value={data.firstName}
											onChange={(e) => handleChange(e)}
										/>
										<input
											type="text"
											name="lastName"
											id="lastName"
											placeholder="Last Name"
											className="form-control"
											autoComplete="off"
											value={data.lastName}
											onChange={(e) => handleChange(e)}
										/>
									</div>

									<input
										type="text"
										name="userPhone"
										placeholder="Contact Number"
										className="form-control"
										autoComplete="off"
										value={data.phone}
										onChange={(e) => handleChange(e)}
									/>
									<input
										type="email"
										name="userEmail"
										placeholder="Work Email"
										className="form-control"
										autoComplete="off"
										value={data.email}
										onChange={(e) => handleChange(e)}
									/>
									<div className="custom-select-pop w-100 ">
										<select
											id="industry"
											className="form-control"
											aria-label="Industry"
										>
											<option value="" selected="" hidden="">
												Industry
											</option>
											<option
												value="Aerospace"
												aria-selected="false"
												className="selected"
											>
												Aerospace
											</option>
											<option
												value="Automotive"
												aria-selected="false"
												className="selected"
											>
												Automotive
											</option>
											<option
												value="Banking"
												aria-selected="false"
												className="selected"
											>
												Banking
											</option>
											<option
												value="Capital Markets"
												aria-selected="false"
												className=""
											>
												Capital Markets
											</option>
											<option
												value="Communications, Media, Information Services"
												aria-selected="false"
											>
												Communications, Media, Information Services
											</option>
											<option
												value="Consumer Goods and Distribution"
												aria-selected="false"
											>
												Consumer Goods and Distribution
											</option>
											<option value="Education" aria-selected="false">
												Education
											</option>
											<option
												value="Energy, Resources, Utilities"
												aria-selected="false"
											>
												Energy, Resources, Utilities
											</option>

											<option value="Healthcare" aria-selected="false">
												Healthcare
											</option>
											<option value="Insurance" aria-selected="false">
												Insurance
											</option>
											<option value="Life Sciences" aria-selected="false">
												Life Sciences
											</option>
											<option value="High Tech" aria-selected="false">
												High Tech
											</option>
											<option value="Manufacturing" aria-selected="false">
												Manufacturing
											</option>
											<option value="Public Services" aria-selected="false">
												Public Services
											</option>
											<option value="Retail" aria-selected="false">
												Retail
											</option>
											<option
												value="Transportation and Logistics"
												aria-selected="false"
											>
												Transportation and Logistics
											</option>
											<option
												value="Travel and Hospitality"
												aria-selected="false"
											>
												Travel and Hospitality
											</option>

											<option value="Others" aria-selected="false">
												Others
											</option>
										</select>
									</div>
									<textarea
										className="form-control"
										name="userMessage"
										rows={4}
										placeholder="How can we help you ?"
										defaultValue={""}
										value={data.message}
										onChange={(e) => handleChange(e)}
									/>
									<div className="check-box mb-2">
										<input
											type="checkbox"
											name="consent-checkbox"
											className="checkbox"
											id="consent-checkbox"
											checked={data.isConsentToPersonalDataProcessing}
											onChange={(e) => handleChange(e)}
										/>
										<label htmlFor="consent-checkbox" className="m-0">
											I consent to the processing of my personal data, given
											above, for RedBlackTree to contact me.
										</label>
									</div>
									<div className="check-box mb-4">
										<input
											type="checkbox"
											name="consent-2-checkbox"
											className="checkbox"
											id="consent-2-checkbox"
											checked={data.isConsentToMarketingCommunication}
											onChange={(e) => handleChange(e)}
										/>
										<label htmlFor="consent-checkbox" className="m-0">
											I would like to receive details about products, services
											and events from RedBlackTree.
										</label>
									</div>
									<div className="mb-3 text-danger">{error}</div>
									<div className="buttons">
										<button
											className="btn btn-medium btn-rounded btn-white btn-outline-info  main-font "
											onClick={onClose}
										>
											Cancel
										</button>
										<button
											className="btn btn-medium btn-rounded btn-white submit-btn main-font "
											onClick={handleSubmit}
										>
											Submit
										</button>
									</div>
								</div>
							</div>
						</div>
						<div
							className={
								show
									? "col-lg-5 col-12 py-5  hide-section2 position-relative h-100"
									: "col-lg-5 col-12 py-5  hide-section2 d-none  position-relative h-100"
							}
						>
							<button className="close-btn mb-3" onClick={onClose}>
								<i class="fas fa-chevron-left"></i> Back
							</button>
							<div className="card px-5 py-4 h-100 justify-content-center ">
								<div
									className="main-title wow zoomIn text-center style-two "
									data-wow-delay="300ms"
								>
									<h2 className="heading">Thank you!</h2>
									<p>We will get back to you shortly.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Popup;
