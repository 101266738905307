import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation, Navigate } from "react-router-dom";
import { Home } from "./Pages/Home";
import { Labs } from "./Pages/labs";
import { Services } from "./Pages/services";
import { Careers } from "./Pages/careers";
import { About } from "./Pages/about";
import { AppDevMain } from "./Pages/Service/appDevMain";
import { ProductEngineering } from "./Pages/Service/productEngineering";
import { AgileDevelopment } from "./Pages/Service/agileDevelopment";
import { BigData } from "./Pages/Service/bigData";
import { LowCode } from "./Pages/Service/lowCode";
import ServiceRoot from "./components/serviceRoot";
import { Resources } from "./Pages/resources";
import { CaseDetails } from "./Pages/CaseDetails";
import "./components/Styles/style.css";
import "./components/Styles/footer.css";
import "./components/Styles/bundle.min.css";
import "./components/Styles/cubeportfolio.min.css";
import { BlogDeatils } from "./Pages/BlogDetails";
import Popup from "./components/Popup/Popup";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import ReactGA from "react-ga4";

const Page = () => {
	const location = useLocation();

	useEffect(() => {
		console.log("GA connected");
		ReactGA.send({
			hitType: "pageview",
			page: location.pathname,
			title: location.pathname,
		});
	}, [location]);

	return (
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/labs" element={<Labs />} />
			<Route path="/services/*" element={<ServiceRoot />} />
			<Route path="/careers" element={<Careers />} />
			<Route path="/contact-us" element={<Popup />} />
			<Route path="/resources" element={<Resources />} />
			<Route path="/privacy-policy" element={<PrivacyPolicy />} />
			<Route path="/resources/caseStudy/:id" element={<CaseDetails />} />
			<Route path="/resources/blog/:id" element={<BlogDeatils />} />
			<Route path="*" element={<Navigate to="/" />} />
		</Routes>
	);
};
function App() {
	return (
		<BrowserRouter>
			<Page />
		</BrowserRouter>
	);
}

export default App;
