import React from "react";
import BlogBoxes from "./BlogBoxes";
const BlogComponent = (props) => {
	return (
		<section id="blog " className={props?.className}>
			<div className="container blog">
				<div className="row ">
					<div className="col-md-12 ">
						<div
							className="main-title wow fadeIn  text-left style-two "
							data-wow-delay="300ms"
						>
							<h2 className="heading">{props?.heading}</h2>
							<p className="text-left">{props?.description}</p>
						</div>
					</div>
				</div>
				<BlogBoxes blogs={props?.blogs} />
			</div>
		</section>
	);
};

export default BlogComponent;
