import React from "react";
import { Footer } from "../../components/footer";
import { Header } from "../../components/Header/header";
import { Outlet } from "react-router-dom";
import "../../components/Styles/agile.css";
export const BigData = () => {
	return (
		<div>
			<>
				{/* Header start */}
				<Header />
				{/* Header end */}
				{/* Home Banner starts */}
				<section className="bigData-home">
					<div className="bg-overlay bg-black opacity-7" />
					<div className="container d-flex align-items-center justify-content-center text-left h-100">
						<div
							className="col-12 col-md-12 px-0 section-heading about-fadeIn wow"
							data-wow-delay=".5s"
						>
							<div className="mb-4 mb-md-0">
								<h2
									className="heading wow fadeIn text-white font-weight-500"
									data-wow-delay=".6s"
								>
									Big Data
								</h2>
								<div className="wow zoomIn col-md-9 p-0" data-wow-delay=".8s">
									<h5 className="mb-4 about-p">
										Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
										sodales lobortis vehicula. Aliquam sodales turpis a neque
										sagittis.
									</h5>
								</div>
							</div>
							<div className="talk-to-us">
								<a
									href="/services"
									className="btn-setting btn-scale btn-blue text-white learn-more "
								>
									Explore{"    "}
									<i className="fa fa-arrow-right ml-1" aria-hidden="true"></i>
								</a>
							</div>
						</div>
					</div>
				</section>
				{/* Home banner ends */}
				{/* Footer starts */}
				<footer className="bg-light-gray footer-transform-padding">
					<Footer />
				</footer>
				{/* Footer ends */}
			</>
			<Outlet />
		</div>
	);
};
