import rbt_logo from "../../Images/rbt-white.png";
import rbt_pink_logo from "../../Images/rbt-pink.png";
import { useLocation } from "react-router-dom";
import $ from "jquery";
export function Header() {
	if ($("nav.navbar").hasClass("static-nav")) {
		$(window).on("scroll", function () {
			var $scroll = $(window).scrollTop();
			if ($scroll >= 80) {
				$("header").addClass("header-appear");
			} else {
				$("header").removeClass("header-appear");
			}
		});
	}
	if ($("nav.navbar").hasClass("bottom-nav")) {
		var navHeight = $(".bottom-nav").offset().top;
		$(window).on("scroll", function () {
			if ($(window).scrollTop() > navHeight) {
				$(".bottom-nav").addClass("fixed-menu");
			} else {
				$(".bottom-nav").removeClass("fixed-menu");
			}
		});
	}
	if ($("nav.navbar").hasClass("bottom-nav")) {
		$(window).on("scroll", function () {
			var $scroll = $(window).scrollTop();
			var $bottom = $(".bottom-nav");
			if ($scroll >= 400) {
				$bottom.addClass("scroll-menu");
			} else {
				$bottom.removeClass("scroll-menu");
			}
		});
	}

	$(window).on("scroll", function () {
		if ($(this).scrollTop() >= 80) {
			$("header").addClass("header-appear");
		} else {
			$("header").removeClass("header-appear");
		}
	});

	const handleSideMenuClick = () => {
		if ($("#sidemenu_toggle").length) {
			var $sideMenu = $(".side-menu");

			$(".pushwrap").toggleClass("active");
			$sideMenu.toggleClass("side-menu-active");
			$("#close_side_menu").fadeToggle(700);

			// Get the current URL
			var currentUrl = window.location.pathname;

			// Iterate through the menu items and add the 'active' class to the matching item
			$(".side-menu .nav-link").each(function () {
				var menuItemUrl = $(this).attr("href");
				if (currentUrl === menuItemUrl) {
					$(this).addClass("active");
				}
			});
		}
	};
	const handleCloseSideMenu = () => {
		$(".side-menu").removeClass("side-menu-active");
		$(this).fadeOut(200);
		$(".pushwrap").removeClass("active");
	};
	const handleSideNavClose = () => {
		$(".side-menu").removeClass("side-menu-active");
		$("#close_side_menu").fadeOut(200);
		$(".pushwrap").removeClass("active");
	};
	if ($("#sidemenu_toggle").length) {
		$(".side-nav .navbar-nav .nav-link.pagescroll").on("click", function () {
			$(".side-menu").removeClass("side-menu-active");
			$("#close_side_menu").fadeOut(200);
			$(".pushwrap").removeClass("active");
		});
	}
	//assigning location variable
	const location = useLocation();
	//destructuring pathname from location
	const { pathname } = location;

	//Javascript split method to get the name of the path in array
	const splitLocation = pathname.split("/");
	return (
		<div>
			<header>
				<nav className="navbar navbar-top-default navbar-expand-lg static-nav transparent-bg nav-bottom-line">
					<div className="container">
						<a className="logo" href="/">
							<img
								src={rbt_logo}
								alt="logo"
								title="Logo"
								className="logo-default"
								style={{ maxWidth: "60%", marginTop: "14px" }}
							/>
							<img
								src={rbt_pink_logo}
								alt="logo"
								title="Logo"
								className="logo-scrolled"
								style={{ maxWidth: "55%" }}
							/>
						</a>
						<div className="collapse navbar-collapse d-none d-lg-block">
							<ul className="nav navbar-nav ml-auto mr-4">
								<li className="nav-item">
									{" "}
									<a
										href="/"
										className={
											splitLocation[1] === ""
												? "scroll nav-link active"
												: "scroll nav-link "
										}
									>
										Home
									</a>
									
								</li>
								<li className="nav-item dropdown position-relative">
									{" "}
									<a
										href="/services"
										className={
											splitLocation[1] === "services"
												? "scroll nav-link active"
												: "scroll nav-link "
										}
									>
										Services
									</a>
								</li>
								<li className="nav-item">
									{" "}
									<a
										href="/labs"
										className={
											splitLocation[1] === "labs"
												? "scroll nav-link active"
												: "scroll nav-link "
										}
									>
										Labs
									</a>
								</li>
								<li className="nav-item">
									{" "}
									<a
										href="/resources"
										className={
											splitLocation[1] === "resources"
												? "scroll nav-link active"
												: "scroll nav-link "
										}
									>
										Resources
									</a>
								</li>
								<li className="nav-item">
									<a
										href="/careers"
										className={
											splitLocation[1] === "careers"
												? "scroll nav-link active"
												: "scroll nav-link "
										}
									>
										Careers
									</a>
								</li>
							</ul>
						</div>
						{/* side menu open button */}
						<button
							className="menu_bars d-inline-block menu-bars-setting"
							id="sidemenu_toggle"
							onClick={handleSideMenuClick}
						>
							<div className="menu-lines">
								<span />
								<span />
								<span />
							</div>
						</button>
					</div>
				</nav>
				<div className="side-menu">
					<div className="inner-wrapper nav-icon">
						<span
							className="btn-close"
							id="btn_sideNavClose"
							onClick={handleSideNavClose}
						/>
						<nav className="side-nav w-100">
							<ul className="navbar-nav">
								<li className="nav-item">
									<a
										href="/"
										className={
											splitLocation[1] === ""
												? "scroll nav-link active"
												: "scroll nav-link "
										}
									>
										Home
									</a>
								</li>
								<li className="nav-item">
									<a
										href="/services"
										className={
											splitLocation[1] === "services"
												? "scroll nav-link active"
												: "scroll nav-link "
										}
									>
										Services
									</a>
								</li>
								<li className="nav-item">
									<a
										href="/labs"
										className={
											splitLocation[1] === "labs"
												? "scroll nav-link active"
												: "scroll nav-link "
										}
									>
										Labs
									</a>
								</li>
								<li className="nav-item">
									<a
										href="/resources"
										className={
											splitLocation[1] === "resources"
												? "scroll nav-link active"
												: "scroll nav-link "
										}
									>
										Resources
									</a>
								</li>
								<li className="nav-item">
									<a
										href="/careers"
										className={
											splitLocation[1] === "careers"
												? "scroll nav-link active"
												: "scroll nav-link "
										}
									>
										Careers
									</a>
								</li>
							</ul>
						</nav>
						<div className="side-footer text-white w-100">
							<ul className="social-icons-simple">
								<li className="side-menu-icons">
									<a
										href="https://www.linkedin.com/company/redblacktree"
										target="_blank"
										rel="noreferrer"
									>
										<i className="fab fa-linkedin-in color-white"></i>
									</a>
								</li>
								<li className="side-menu-icons">
									<a
										href="https://twitter.com/rbt_tech"
										target="_blank"
										rel="noreferrer"
									>
										<i className="fab fa-twitter color-white"></i>
									</a>
								</li>
								<li className="side-menu-icons">
									<a
										href="https://www.facebook.com/RedBlackTree.fb"
										target="_blank"
										rel="noreferrer"
									>
										<i className="fab fa-facebook-f color-white" />
									</a>
								</li>
								<li className="side-menu-icons">
									<a
										href="https://www.instagram.com/rbt_tech/"
										target="_blank"
										rel="noreferrer"
									>
										<i className="fab fa-instagram color-white"></i>
									</a>
								</li>
							</ul>
							<p className="text-white">
								© 2023 MegaOne. Made With Love by Themesindustry
							</p>
						</div>
					</div>
				</div>
				<button id="close_side_menu" onClick={handleCloseSideMenu} />
				{/*Side Menu*/}
			</header>
		</div>
	);
}
