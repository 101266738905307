import React, { useEffect, useState } from "react";
import "./Styles/PeopleGallary.css";
import peopleData from "./employee.json";
import $ from "jquery";
import Marquee from "react-marquee-slider";
import times from "lodash/times";
import { nanoid } from "nanoid";

const PeopleCarousel = () => {
	const [key, setKey] = useState(nanoid());
	const velocity = 20;
	useEffect(() => {
		setKey(nanoid());
	}, [setKey]);
	const handleMouseEnter = (index) => {
		$(".slider .slide-track").addClass("animation-none ");
		// $(".person-details").removeClass("d-none");
		$(`.slide-${index}`).find(".person-details").removeClass("d-none");
		$(".slider .slide-track div").css("animation-play-state", "paused");
	};
	const handleMouseLeave = () => {
		$(".slider .slide-track").removeClass("animation-none ");
		$(".person-details").addClass("d-none");
		$(".slider .slide-track div").css("animation-play-state", "running");
	};

	return (
		<section id="team" className="nav-animation-element">
			<div className="section-container">
				<div className="container main-title wow fadeIn  text-left style-two mb-4">
					<h2 className="heading">Our Awesome Team</h2>
					<br />
					<p className="servicecontent">
						Our diverse, brilliant and hard working team - changing our world
						one day at a time.
					</p>
				</div>

				<div className="slider">
					<div
						className="slide-track position-relative mx-3"
						style={{ width: `calc(70px * ${peopleData.length})` }}
					>
						<Marquee
							key={key}
							velocity={velocity}
							scatterRandomly
							minScale={0.7}
							maxScale={1.5}
							Motio
							resetAfterTries={100}
						>
							{times(peopleData.length, String).map((id) => (
								<div
									className={`person-card slide slide-${id} wow zoomIn`}
									data-wow-delay="0.8s"
									key={`marquee-example-review-${id}`}
								>
									<img
										className="round-image"
										src={`${peopleData[id].photoURL}`}
										alt={peopleData[id].firstName}
										onMouseEnter={() => handleMouseEnter(id)}
										onMouseLeave={handleMouseLeave}
									/>
									<div className="person-details d-none">
										<h3>{peopleData[id].firstName}</h3>
										<p>{peopleData[id].description}</p>
									</div>
								</div>
							))}
						</Marquee>
					</div>
				</div>
			</div>
		</section>
	);
};

export default PeopleCarousel;
