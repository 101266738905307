import React from "react";
import agile from "../../Images/agile2.jpg";
import bigData from "../../Images/Big-data1.jpg";
import proEng from "../../Images/prod-lab.jpg";
import lowcode from "../../Images/low-code.jpg";
import AIImg from "../../Images/AI.jpg";
import appMainDev from "../../Images/app-dev1.jpg";
import techSupport from "../../Images/tech-support.jpg";
import mendixWhite from "../../Images/logos/Mendix-Logo.wine (1).png";
import microsoftPowerPlatform from "../../Images/logos/Microsoft-power-platform-1.png";
import aerospike from "../../Images/logos/AEROSPIKE-logo-1.png";

const OurOfferingsComponent = (props) => {
	return (
		<section className="ourofferings">
			<div className="container">
				<div className="row">
					<div className="col-sm-12">
						<div className="container-fluid">
							<div id="js-grid-slider-services" className="cbp">
								<div className="cbp-item heading">
									<div className="cbp-caption offer-heading">
										<div className="cbp-caption-defaultWrap">
											<div
												className="main-title wow fadeIn  text-left style-two mb-4"
												data-wow-delay="300ms"
											>
												<h2 className="heading"> Our service offerings </h2>
												<h5>
													RedBlackTree has built a strong reputation, in over 20
													years of dedicated service to customers, in delivering
													transformative business solutions across geographies
													and multiple industry verticals.
													<br />
													<br />
													With our rigourous approach to engineering,
													disciplined execution, attention to detail, a
													continuous learning culture, the best talent and
													strong technical partnerships, we help our customers
													innovate and transform faster than ever today.
												</h5>
											</div>
											<div className="row m-0">
												<div className="d-flex align-items-center flex-column flex-md-row justify-content-md-between w-100">
													<div
														className="about-box center-block wow zoomIn"
														data-wow-delay="400ms"
													>
														<div className="about-opacity-icon">
															{" "}
															<i
																className="fa fa-users"
																aria-hidden="true"
															></i>{" "}
														</div>
														<div className="about-main-icon pb-4">
															<i className="fa fa-users" aria-hidden="true"></i>
														</div>
														<h5 className="mb-0">Lean Teams</h5>
													</div>

													<div
														className="about-box center-block wow zoomIn"
														data-wow-delay="600ms"
													>
														<div className="about-opacity-icon">
															{" "}
															<i
																className="fa fa-chart-line"
																aria-hidden="true"
															/>{" "}
														</div>
														<div className="about-main-icon pb-4">
															<i
																className="fa fa-chart-line"
																aria-hidden="true"
															/>
														</div>
														<h5 className="mb-0">High-Quality</h5>
													</div>

													<div
														className="about-box  center-block wow zoomIn"
														data-wow-delay="500ms"
													>
														<div className="about-opacity-icon">
															{" "}
															<i
																className="fa fa-truck"
																aria-hidden="true"
															></i>{" "}
														</div>
														<div className="about-main-icon pb-4">
															<i className="fa fa-truck" aria-hidden="true"></i>
														</div>
														<h5 className="mb-0">On-time Delivery</h5>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div id="prod-eng" className="cbp-item">
									<div className="cbp-caption">
										<div className="cbp-caption-defaultWrap">
											<img src={proEng} alt="" />
											<h4 className="img-heading">Product Labs</h4>
										</div>
										<div className="cbp-caption-activeWrap">
											<div className="cbp-l-caption-alignCenter">
												<div className="cbp-l-caption-body">
													<div className="cases-image-inner">
														<div className="content">
															<h3 className="text-white hover-heading font-secondary px-4">
																Product Labs
															</h3>
															<p className="service-content d-block p-4">
																RedBlackTree’s Product Labs are our own humble
																(but very capable) moonshot team that builds
																solutions for the modern enterprise, helping
																them build, launch and scale new software
																products. <br />
																<br />
																We bring clean design, solid engineering and
																great people together to solve enterprise
																problems for broad horizontal markets.
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div id="big-data" className="cbp-item">
									<div className="cbp-caption">
										<div className="cbp-caption-defaultWrap">
											<img src={bigData} alt="" />
											<h4 className="img-heading">Big Data</h4>
										</div>
										<div className="cbp-caption-activeWrap">
											<div className="cbp-l-caption-alignCenter">
												<div className="cbp-l-caption-body">
													<div className="cases-image-inner">
														<div className="content">
															<h3 className="text-white hover-heading  font-secondary px-4">
																Big Data - Applications, Analytics & AI
															</h3>
															<p className="service-content d-block p-4">
																Improve application performance and scale your
																backend systems with RedBlackTree.
																<br />
																<br />
																We specialize in building highly scalable,
																event-driven, low latency systems with Rust,
																NodeJs, Kafka and Aerospike on Amazon AWS and
																Microsoft Azure.
																<div className="icons ">
																	<div className="icon">
																		<img
																			src={aerospike}
																			alt=""
																			className="aerospike"
																		/>
																	</div>
																</div>
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div id="ai" className="cbp-item">
									<div className="cbp-caption">
										<div className="cbp-caption-defaultWrap">
											<img src={AIImg} alt="" />
											<h4 className="img-heading">AI Services</h4>
										</div>
										<div className="cbp-caption-activeWrap">
											<div className="cbp-l-caption-alignCenter">
												<div className="cbp-l-caption-body">
													<div className="cases-image-inner">
														<div className="content">
															<h3 className="text-white hover-heading font-secondary px-4">
																Applied AI Solutions to accelerate enterprise
																re-invention!
															</h3>
															<p className="service-content d-block p-4 mb-0 ">
																We design and build AI solutions based primarily
																on Open-Source Generative Models. We bring
																structured and unstructured data, multi-modal
																LLMs, RAG techniques and other modern AI tools
																to build secure AI systems on company-owned
																server/cloud infrastructure.
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div id="agile-development" className="cbp-item">
									<div className="cbp-caption">
										<div className="cbp-caption-defaultWrap">
											<img src={agile} alt="" />
											<h4 className="img-heading">Agile Development</h4>
										</div>
										<div className="cbp-caption-activeWrap ">
											<div className="cbp-l-caption-alignCenter">
												<div className="cbp-l-caption-body">
													<div className="cases-image-inner">
														<div className="content">
															<h3 className="text-white font-secondary font-weight-600 px-4 hover-heading">
																Agile - a way of life at RedBlackTree
															</h3>
															<p className="service-content d-block p-4">
																We go far beyond standard Agile Software
																Engineering practices to bring people,
																technology and processes together to create and
																deliver business value for our customers.
																<br />
																<br />
																We are constantly building an organization that
																embraces technology, learns and improves
																continuously for our customers.
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div id="low-code" className="cbp-item">
									<div className="cbp-caption">
										<div className="cbp-caption-defaultWrap">
											<img src={lowcode} alt="" />
											<h4 className="img-heading">Low Code</h4>
										</div>
										<div className="cbp-caption-activeWrap">
											<div className="cbp-l-caption-alignCenter">
												<div className="cbp-l-caption-body">
													<div className="cases-image-inner">
														<div className="content">
															<h3 className="text-white hover-heading font-secondary px-4">
																Low Code Services
															</h3>
															<p className="service-content d-block p-4 mb-0">
																Low Code/No Code platforms simplify and speed up
																the development process and reduce overall
																software maintenance costs.
																<br />
																<br />
																RedBlackTree offers low code development
																services with Siemens Mendix and the Microsoft
																Power Platform, enabling enterprises to rapidly
																build a low code solutions layer on top of a
																range of enterprise systems
															</p>
															<div className="icons ">
																<div className="icon">
																	<img
																		src={mendixWhite}
																		alt=""
																		className="mendix"
																	/>
																</div>
																<div className="icon">
																	<img
																		src={microsoftPowerPlatform}
																		alt=""
																		className="microsoft-pow"
																	/>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div id="app-dev-main" className="cbp-item">
									<div className="cbp-caption">
										<div className="cbp-caption-defaultWrap">
											<img src={appMainDev} alt="" />
											<h4 className="img-heading">Application Modernization</h4>
										</div>
										<div className="cbp-caption-activeWrap ">
											<div className="cbp-l-caption-alignCenter">
												<div className="cbp-l-caption-body">
													<div className="cases-image-inner">
														<div className="content">
															<h3 className="text-white hover-heading font-secondary px-4">
																Application Modernization – Build in the Cloud
																with RedBlackTree
															</h3>
															<p className="service-content d-block p-4">
																Migrate your legacy applications and systems to
																private or public clouds with RedBlackTree.
																<br />
																<br />
																Build rich mobile and web experiences with
																modern, cloud-native applications on the
																Microsoft Azure or AWS Clouds.
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div id="tech-support" className="cbp-item">
									<div className="cbp-caption">
										<div className="cbp-caption-defaultWrap">
											<img src={techSupport} alt="" />
											<h4 className="img-heading">Service Desk Solutions</h4>
										</div>
										<div className="cbp-caption-activeWrap">
											<div className="cbp-l-caption-alignCenter">
												<div className="cbp-l-caption-body">
													<div className="cases-image-inner">
														<div className="content">
															<h3 className="text-white hover-heading font-secondary px-4">
																Service Desk Solutions
															</h3>
															<p className="service-content d-block p-4 mb-0 ">
																Use our Service Desk Generative AI tools off
																customer-owned cloud environments to augment
																agents and automate contact centre service
																delivery.
																<br />
																<br />
																Improve Customer Service, the efficiency of
																business processes and reduce costs with our
																24x7 global service desk solutions.
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default OurOfferingsComponent;
