import React, { useState, useEffect } from "react";
import { Header } from "../components/Header/header";
import { Footer } from "../components/footer";
import "../components/Styles/service.css";
import { Outlet } from "react-router-dom";
import CaseComponent from "../components/Common/CaseComponent";
import BannerComponent from "../components/Common/BannerComponent";
import OurOfferingsComponent from "../components/ServiceComponents/OurOfferingsComponent";
import Partners from "../components/ServiceComponents/Partners";
import ServiceEnterprises from "../components/ServiceComponents/ServiceEnterprises";
import BlogComponent from "../components/Common/BlogComponent";
import RequestComponent from "../components/Common/RequestComponent";
import { getBlogMediaById, getBlogs, getCategories } from "../api";

export const Services = () => {
	const [ourBlogs, setOurBlogs] = useState([]);
	const [blogs, setBlogs] = useState([]);
	const [allCategories, setAllCategories] = useState([]);

	useEffect(() => {
		getOurBlogs();
	}, []);
	useEffect(() => {
		getBlogCategories();
	}, [ourBlogs]);
	const getOurBlogs = async () => {
		const blogs = await getBlogs();
		if (Array.isArray(blogs?.data) && blogs?.data?.length > 0) {
			const updatedBlogs = await Promise.all(
				blogs.data.map(async (blog) => {
					const imageUrl = await getBlogMediaById(blog?.featured_media);
					return { ...blog, imageUrl };
				})
			);
			setOurBlogs(updatedBlogs);
		}
	};

	useEffect(() => {
		getBlogsByCategory();
	}, [allCategories]);

	const getBlogCategories = async () => {
		const categories = await getCategories();
		setAllCategories(categories);
	};

	const getBlogsByCategory = async () => {
		const ArticleCategoryId = findCategoryId("Article");
		const categoryBlogs = await getPostsByCategory(ArticleCategoryId);
		// get latest 3 blogs
		if (Array.isArray(categoryBlogs) && categoryBlogs.length > 0) {
			const latestBlogs = categoryBlogs?.slice(0, 3);
			setBlogs(latestBlogs);
		} else {
			setBlogs([]);
		}
		// setCaseStudies(getPostsByCategory(caseStudyCategoryId));
	};
	// funtion to find id of the category by name
	const findCategoryId = (name) => {
		if (Array.isArray(allCategories) && allCategories.length > 0) {
			const category = allCategories?.find(
				(category) => category.name === name
			);
			return category?.id;
		} else {
			return null;
		}
	};

	// function to get posts by article id
	const getPostsByCategory = (id) => {
		const posts = ourBlogs.filter((blog) => blog.categories.includes(id));
		return posts;
	};
	return (
		<div>
			<Header />
			<BannerComponent
				id="service-home"
				isOverlayApplied={true}
				overlayOpacity="opacity-6"
				heading="Accelerate your Digital Journey"
				description="Drive business transformation and continuous innovation with our
								best-in-class Engineering, Data, Low Code and AI services."
				eventPageName="Services"
			/>
			<OurOfferingsComponent />
			<Partners />
			<ServiceEnterprises />
			<BlogComponent
				heading="Featured Insights"
				description="Explore our blog featuring the latest updates, articles and case
				studies from our Engineering, People Ops and other teams."
				blogs={blogs}
			/>
			<RequestComponent
				parentClassName="service-contact mt-0"
				heading="Use Generative AI to transform business processes!"
				description="Schedule a call with our technical experts."
				eventPageName="Services Page"
			/>
			<Footer />
			<Outlet />
		</div>
	);
};
