import React from "react";
import methodical from "../../Images/logos/methodical-logo.svg";
import { raiseGAEvent } from "../../Events/Event";

const MethodicalDetails = (props) => {
	return (
		<section className="bg-lightgrey methodical-details">
			<div className="bg-overlay bg-black opacity-6" />
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-6 col-md-12 p-lg-0">
						<div className="row">
							<div className="col-md-12 ">
								<div
									className="main-title wow fadeIn style-two"
									data-wow-delay="300ms"
								>
									<div className="methodical-logo">
										<img src={methodical} alt="" width={80} height={80} />
										<h5 className="">Methodical</h5>
									</div>
									<h2 className="heading text-left">
										An Operating System for the modern digital enterprise
									</h2>
									<p className="text-left">
										Manage and automate enterprise workflows with Methodical.
										Build apps on top of Methodical and integrate quickly with
										enterprise systems,
										<br /> with little or no code.
									</p>
									<a
										href="https://www.methodical.ai/"
										target="_blank"
										rel="noreferrer"
										className="btn-setting btn-transparent btn-hvr-blue color-white"
										onClick={() =>
											raiseGAEvent(
												"Button click",
												"Learn more - methodical",
												"labs page"
											)
										}
									>
										learn more
										<i
											className="fa fa-arrow-right ml-2"
											aria-hidden="true"
										></i>
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-6 col-md-12 mt-4 mt-md-0 ">
						<div className="hover-effect">
							<img
								alt="stats"
								src="https://rbtwebsite-assets.s3.amazonaws.com/ap-south-1/images/technologies/methodical_screenshot.png"
								className="about-img"
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default MethodicalDetails;
