import React, { useEffect, useState, useMemo } from "react";
import { Footer } from "../components/footer";
import { Header } from "../components/Header/header";
import { Outlet, useParams } from "react-router-dom";
import "../components/Styles/agile.css";
import { blogs, caseStudies } from "../components/data";
import "../components/Styles/case.css";
import { getBlogMediaById, getBlogs, getPostTags } from "../api";
import Select from "react-select";
import countryList from "react-select-country-list";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const CaseDetails = () => {
	let { id } = useParams();

	const [data, setData] = useState({});
	const [relatedData, setRelatedData] = useState([]);
	const [author, setAuthor] = useState({});
	const [tags, setTags] = useState([]);
	const [selectedCompSize, setSelectedCompSize] = useState(null);
	const [selectedCountry, setSelectedCountry] = useState(null);
	const compSizeOptions = [
		{ value: "1-49", label: " 1-49" },
		{ value: "50-11", label: "50-100" },
		{ value: "100-499", label: "100-499" },
		{ value: "500-4999", label: "500-4999" },
		{ value: "5000+", label: "5000+" },
	];
	const countries = useMemo(() => countryList().getData(), []);
	useEffect(() => {
		fetchCaseDetails();
	}, [id]);

	const fetchCaseDetails = async () => {
		const blogs = await getBlogs();
		if (Array.isArray(blogs?.data) && blogs?.data?.length > 0) {
			const updatedBlogs = await addImageUrlsToBlogs(blogs.data);
			const blog = findBlogById(updatedBlogs, id);
			if (blog) {
				setData(blog);
				setAuthor(blog?.authors[0]);
				if (blog?.tags?.length > 0) {
					const tags = await getPostTags(blog?.id);
					setTags(tags);
				}
				const relatedBlogs = updatedBlogs.filter((blog) => blog.id !== id);
				setRelatedData(relatedBlogs);
			}
		}
	};
	const addImageUrlsToBlogs = (blogs) => {
		return Promise.all(blogs.map(addImageUrlToBlog));
	};
	const addImageUrlToBlog = async (blog) => {
		const imageUrl = await getBlogMediaById(blog?.featured_media);
		return { ...blog, imageUrl };
	};
	const findBlogById = (blogs, id) => {
		return blogs.find((blog) => blog?.id == id);
	};
	const customStyles = {
		option: (defaultStyles, state) => ({
			...defaultStyles,
			color: state.isSelected ? "#d91a60" : "#535353",
			backgroundColor: state.isSelected ? "#f3f3f3" : "#fff",
			paddingLeft: "20px !important",
			fontWeight: "400",
		}),
		control: (defaultStyles) => ({
			...defaultStyles,
			fontFamily: "Raleway !important",
			marginBottom: "20px",
			color: "#535353",
			fontWeight: "400",
			padding: "3px 5px",
			"&:focus": {
				borderColor: "inherit",
				boxShadow: "none",
			},
			"&:active": {
				borderColor: "inherit",
				boxShadow: "none",
			},
			"&:hover": {
				borderColor: "inherit",
				boxShadow: "none",
			},
		}),
		singleValue: (defaultStyles) => ({
			...defaultStyles,
			color: "#535353",
		}),
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);
		const formValues = {};
		for (let [key, value] of formData.entries()) {
			formValues[key] = value;
		}
		formValues["companySize"] = selectedCompSize?.value;
		formValues["country"] = selectedCountry?.label;
		// Add condition to check the email is work email or not
		console.log(formValues.email);
		if (
			formValues.name === "" ||
			formValues.email === "" ||
			formValues.company === "" ||
			formValues.phone === "" ||
			formValues.companySize === "" ||
			formValues.country === ""
		) {
			toast.error("Please fill all fields");
			return;
		}
		if (
			formValues.email?.includes("gmail.com") ||
			formValues.email?.endsWith("hotmail.com") ||
			formValues.email?.endsWith("yahoo.com") ||
			formValues.email?.endsWith("outlook.com") ||
			formValues.email?.endsWith("aol.com") ||
			formValues.email?.endsWith("icloud.com")
		) {
			toast.error("Please enter your work email");
			return;
		}
	};
	return (
		<div>
			<>
				{/* Header start */}
				<Header />
				<ToastContainer position="top-center" />

				<section className="page_header pb-0 w-100 blog">
					<div className="bg-overlay bg-black opacity-7" />
					<div className="container">
						<div className="row">
							<div className="col-md-12 page-content position-relative text-white text-center">
								<h2 className="mb-2 wow zoomIn" data-wow-delay=".6s">
									Case Studies
								</h2>
								<a
									href="/resources"
									className="d-inline-block text-white wow zoomIn"
									data-wow-delay=".8s"
								>
									Resources Hub
								</a>{" "}
								<span className="wow zoomIn" data-wow-delay=".9s">
									<i className="fa fa-angle-double-right font-13" /> Case Study
								</span>
							</div>
						</div>
					</div>
				</section>
				{/* Home banner ends */}
				{/* case details starts */}
				<div className="container caseStudy">
					<div className="cbp-l-project-container pt-5">
						<div className="cbp-l-project-desc">
							<div className="blog-listing-inner heading-space-half">
								<div className="image hover-effect">
									<img src={data && data?.imageUrl} alt="case-img" />
								</div>
							</div>
							<div className="cbp-l-project-title">
								{data && data?.title?.rendered}
							</div>
							<div
								className="cbp-l-project-desc-text"
								dangerouslySetInnerHTML={{
									__html: data?.content?.rendered,
								}}
							></div>
						</div>
						<div className="cbp-l-project-details">
							<div
								className="widget heading-space-half wow fadeIn bg-white"
								data-wow-delay="300ms"
							>
								<ul className="cbp-l-project-details-list mb-4  ">
									<li>
										<strong>Author</strong>
										{author?.display_name}
									</li>
									<li>
										<strong>Date</strong>
										{new Date(data?.date).toLocaleDateString("en-US", {
											day: "numeric",
											month: "long",
											year: "numeric",
										})}
									</li>
									<li>
										<strong>Tags</strong>
										{tags.map((tag) => tag.name).join(", ")}
									</li>
								</ul>
							</div>
							{/* Recent Post */}
							<div
								className="widget heading-space-half wow fadeIn bg-white"
								data-wow-delay="350ms"
							>
								<h5 className="color-black font-weight-600 text-capitalize mb-2">
									To Download
								</h5>
								<p className="color-grey line-height-25px mb-2rem">
									Please fill all fields
								</p>
								<div className="company-contact-form ">
									<form
										className="contact-form-outer"
										onSubmit={(e) => handleSubmit(e)}
									>
										<div className="row">
											<div className="col-md-12 col-sm-12">
												<div className="contact-form-textfield pb-4">
													<input
														type="text"
														placeholder="Name"
														className="form-control"
														required=""
														id="name"
														name="name"
													/>
												</div>
											</div>
											<div className="col-md-12 col-sm-12">
												<div className="contact-form-textfield pb-4">
													<input
														type="email"
														placeholder="Work Email"
														className="form-control"
														required=""
														id="email"
														name="email"
													/>
												</div>
											</div>
											<div className="col-md-12 col-sm-12">
												<div className="contact-form-textfield pb-4">
													<input
														type="text"
														placeholder="Company"
														className="form-control"
														required=""
														id="company"
														name="company"
													/>
												</div>
											</div>
											<div className="col-md-12 col-sm-12">
												<div className="contact-form-textfield pb-4">
													<input
														type="tel"
														placeholder="Contact No"
														className="form-control"
														id="phone"
														name="phone"
													/>
												</div>
											</div>
											<div className="col-md-12 col-sm-12">
												<div className="contact-form-textfield pb-4">
													<Select
														defaultValue={selectedCompSize}
														placeholder="Company Size"
														onChange={setSelectedCompSize}
														options={compSizeOptions}
														className="contact-form-select"
														styles={customStyles}
													/>
												</div>
											</div>
											<div className="col-md-12 col-sm-12">
												<div className="contact-form-textfield pb-4">
													<Select
														defaultValue={selectedCountry}
														placeholder="Select Country"
														onChange={setSelectedCountry}
														options={countries}
														className="contact-form-select"
														styles={customStyles}
													/>
												</div>
											</div>
											<div className="col-lg-12 pt-xs-25px text-center">
												<button
													type="submit"
													className="btn-setting btn-scale btn-green text-white w-100 z-0 "
												>
													<b className="font-weight-normal">Download</b>
												</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
					<div className="cbp-l-project-container">
						<div className="cbp-l-project-related">
							<div className="cbp-l-project-desc-title">
								<span>Related {"Cases"}</span>
							</div>
							<ul className="cbp-l-project-related-wrap">
								{relatedData &&
									relatedData.map((item, index) => {
										return (
											<li className="cbp-l-project-related-item" key={index}>
												<a
													href={`/resources/${item.type}/${item.id}`}
													className="cbp-singlePage cbp-l-project-related-link"
													rel="nofollow"
												>
													<img src={item.image} alt="" />
													<div className="cbp-l-project-related-title">
														{item.name}
													</div>
												</a>
											</li>
										);
									})}
							</ul>
						</div>
					</div>
					<br />
					<br />
					<br />
				</div>
				{/* case details ends */}
				{/* Footer starts */}

				<Footer />
				{/* Footer ends */}
			</>
			<Outlet />
		</div>
	);
};
