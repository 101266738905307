import React from "react";
import designIcon from "../../Images/ui-design.png";
import prototypeIcon from "../../Images/prototype.png";
import production from "../../Images/production.png";
import scaling from "../../Images/scalling.png";
import { raiseGAEvent } from "../../Events/Event";

const StartUpStudio = (props) => {
	return (
		<section id="startUp" className="startUp">
			<div className="container">
				<div className="row">
					<div className="box-1 col-md-7 col-12 p-5">
						<div className="row">
							<div className="col-12 ">
								<div
									className="main-title wow fadeIn style-two"
									data-wow-delay="300ms"
								>
									<h2 className="heading text-left">Startup Studio</h2>
									<p className="text-left">
										We work with startups and large enterprise customers to
										design, prototype, experiment, and build new products. We
										iterate fast with nimble teams. We hire only the best talent
										for our startup teams.
										<br />
										<br />
										We've helped establish and scale several startups from the
										US and Europe over the last 2 decades.
									</p>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-3 col-sm-12 mb-xs-2rem text-center">
								<div
									className="prod-card wow zoomIn ml-auto ml-md-0 mr-auto mr-md-0"
									data-wow-delay="500ms"
								>
									<img src={designIcon} alt="" width={80} height={80} />
									<h5 className="">Design</h5>
								</div>
							</div>
							<div className="col-md-3 col-sm-12 mb-xs-2rem text-center">
								<div
									className="prod-card wow zoomIn ml-auto ml-md-0 mr-auto mr-md-0"
									data-wow-delay="600ms"
								>
									<img
										src={prototypeIcon}
										alt=""
										width={80}
										height={80}
										className="juno-img"
									/>
									<h5 className="">Prototyping</h5>
								</div>
							</div>
							<div className="col-md-3 col-sm-12 mb-xs-2rem text-center">
								<div
									className="prod-card wow zoomIn ml-auto ml-md-0 mr-auto mr-md-0"
									data-wow-delay="600ms"
								>
									<img
										src={production}
										alt=""
										width={80}
										height={80}
										className="juno-img"
									/>
									<h5 className="">Product Engineering</h5>
								</div>
							</div>
							<div className="col-md-3 col-sm-12 mb-xs-2rem text-center">
								<div
									className="prod-card wow zoomIn ml-auto ml-md-0 mr-auto mr-md-0"
									data-wow-delay="600ms"
								>
									<img
										src={scaling}
										alt=""
										width={80}
										height={80}
										className="juno-img"
									/>
									<h5 className="">Scaling</h5>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12 p-0">
								<div className="talk-to-us-parent">
									<div className="container   ">
										<div
											className="main-title wow fadeIn style-two mb-0"
											data-wow-delay="300ms"
										>
											<p className="text-left mr-3">
												Are you working on a new product idea? We can help!{" "}
											</p>
										</div>
										<div className="talk-to-us">
											<a
												href="/contact-us"
												className=" btn btn-setting btn-outline-info rounded text-white wow zoomIn mt-3"
												data-wow-delay=".8s"
												onClick={() =>
													raiseGAEvent(
														"Button click",
														"Talk to us - startup studio",
														"labs page"
													)
												}
											>
												Talk To Us{" "}
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default StartUpStudio;
