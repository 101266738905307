import React from "react";
import { raiseGAEvent } from "../../Events/Event";
const CareersBanner = (props) => {
	return (
		<section id="careers-home">

			<div className="container d-flex align-items-center justify-content-center text-left h-100">
				<div
					className="col-12 col-md-12 px-0 section-heading about-fadeIn wow"
					data-wow-delay=".5s"
				>
					<div className="mb-4 mb-md-0">
						<h2
							className="heading wow fadeIn text-white font-weight-500"
							data-wow-delay=".6s"
						>
							Work with RedBlackTree
						</h2>
						<div className="wow zoomIn p-0" data-wow-delay=".8s">
							<h5 className="mb-4 about-p">
								We are looking for people who{" "}
								<i className="fa fa-heart" style={{ color: "#BB272C" }}></i>{" "}
								technology and are passionate about solving the world's
								problems. Check out open positions with us and with our startup
								companies.
							</h5>
						</div>
					</div>
					<div className="talk-to-us">
						<a
							href="https://angel.co/redblacktree/jobs"
							target="_blank"
							rel="noreferrer"
							className=" btn btn-setting job-btn rounded text-white wow zoomIn"
							data-wow-delay=".8s"
							onClick={() =>
								raiseGAEvent(
									"Button Click",
									"Jobs at RBT - banner",
									"Careers Page"
								)
							}
						>
							Jobs at RedBlackTree{" "}
							<i className="fa fa-arrow-right ml-1" aria-hidden="true"></i>
						</a>
					</div>
				</div>
			</div>
		</section>
	);
};

export default CareersBanner;
