import React from "react";
import { Tweet } from "react-tweet";

const SocialMedia = (props) => {
	const tweets = [
		{
			id: "1750465465871130847",
		},
		{
			id: "1746127909075423276",
		},
		{
			id: "1740683460983853100",
		},
	];
	return (
		<section className="social-media bg-light-gray3">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div
							className="main-title wow fadeIn  text-left style-two mb-4"
							data-wow-delay="300ms"
						>
							<h2 className="heading">RedBlackTree on Social Media</h2>
						</div>
					</div>
				</div>
				<div className="row">
					{tweets.map((tweet) => {
						return (
							<div key={tweet.id} className="col-md-4 col-12">
								<Tweet id={tweet.id} />
							</div>
						);
					})}
				</div>
			</div>
		</section>
	);
};

export default SocialMedia;
