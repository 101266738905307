import React from "react";

const HomeValues = (props) => {
	return (
		<section id="values" className=" values">
			<div className="container value-card ">
				<div className="row align-items-center values-new">
					<div className="col-12">
						<div className="main-title style-two text-left col-12 mb-0">
							<div className=" ">
								<h2 className="heading">Attention to Detail</h2>
								<div className="wow fadeIn" data-wow-delay=".6s">
									<span className="about-p text-dark home ">
										At RedBlackTree, we've learnt over the years, that
										<i> attention to detail </i> &nbsp;is often the difference
										between success and failure. <i> Attention to detail </i>
										&nbsp;helps us produce impeccably engineered, finely crafted
										software, on time, for our customers. Done consistently,
										over time, this results in sustained, long term value for
										our customers.
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default HomeValues;
