import React from "react";
import microsoft from "../../Images/microsoft_partner_logo.jpg";
import aws from "../../Images/aws.png";
import mendix from "../../Images/Mendix-Logo.wine.png";
import aerospike from "../../Images/logos/AEROSPIKE-logo-1.png";
const Partners = (props) => {
	return (
		<section className="partners">
			<div className="bg-overlay partner-overlay"></div>
			<div className=" container">
				<div className="row">
					<div className="col-md-12">
						<div
							className="main-title wow fadeIn  style-two"
							data-wow-delay="300ms"
						>
							<h2 className="heading text-left"> Technology Partners</h2>
							<p className="text-left">
								Our customers benefit directly from our partnerships with world
								leaders in technology.
							</p>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="service-partners">
							<a
								href="https://aerospike.com/"
								target="_blank"
								rel="noreferrer"
								className="card"
							>
								<img
									alt="aerospike-logo"
									src={aerospike}
									className="wow zoomIn"
									data-wow-delay=".2s"
								/>
							</a>
							<a
								href="https://www.mendix.com/"
								target="_blank"
								rel="noreferrer"
								className="card"
							>
								<img
									alt="mentix-logo"
									src={mendix}
									className="wow zoomIn"
									data-wow-delay=".4s"
								/>
							</a>
							<a
								href="https://aws.amazon.com/"
								target="_blank"
								rel="noreferrer"
								className="card"
							>
								<img
									alt="aws-logo"
									style={{ height: "60px" }}
									src={aws}
									className="wow zoomIn"
									data-wow-delay=".6s"
								/>
							</a>
							<a
								href="https://www.microsoft.com/en-in"
								target="_blank"
								rel="noreferrer"
								className=" card "
							>
								<img
									alt="microsoft-logo"
									src={microsoft}
									className="wow zoomIn"
									data-wow-delay=".8s"
								/>
							</a>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Partners;
