import React from "react";
import databaseIcon from "../../Images/database (1).png";
import aiIcon from "../../Images/ai_blue.png";
import cloudIcon from "../../Images/cloud (1).png";
import appDevIcon from "../../Images/app-development (1).png";
import lowCodeIcon from "../../Images/dashboard (1).png";
import techSupportIcon from "../../Images/support.png";
const HomeOurExpertise = (props) => {
	return (
		<section className="our-Expertise">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div
							className="main-title wow fadeIn  text-left style-two "
							data-wow-delay="300ms"
						>
							<h2 className="heading">Our Expertise</h2>
							<p className="text-left p-0">
								We drive sustained business growth for customers with our agile
								practices, deep technology expertise, our culture of engineering
								excellence and above all, with our exceptional people.
							</p>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-4 col-sm-12 mb-xs-2rem text-center">
						<div
							className="prod-card wow zoomIn methodical ml-auto ml-md-0 mr-auto mr-md-0"
							data-wow-delay="500ms"
						>
							<img src={databaseIcon} alt="" width={80} height={80} />
							<h5 className="">Data at Scale</h5>
						</div>
					</div>
					<div className="col-md-4 col-sm-12 mb-xs-2rem text-center">
						<div
							className="prod-card wow zoomIn ml-auto ml-md-0 mr-auto mr-md-0"
							data-wow-delay="600ms"
						>
							<img
								src={aiIcon}
								alt=""
								width={80}
								height={80}
								className="juno-img"
							/>
							<h5 className="">AI & Machine Learning</h5>
						</div>
					</div>
					<div className="col-md-4 col-sm-12 mb-xs-2rem text-center">
						<div
							className="prod-card wow zoomIn ml-auto ml-md-0 mr-auto mr-md-0"
							data-wow-delay="600ms"
						>
							<img
								src={cloudIcon}
								alt=""
								width={80}
								height={80}
								className="juno-img"
							/>
							<h5 className="">Cloud</h5>
						</div>
					</div>
					<div className="col-md-4 col-sm-12 mb-xs-2rem text-center">
						<div
							className="prod-card wow zoomIn ml-auto ml-md-0 mr-auto mr-md-0"
							data-wow-delay="600ms"
						>
							<img
								src={lowCodeIcon}
								alt=""
								width={80}
								height={80}
								className="juno-img low-code-img"
							/>
							<h5 className="">Low Code Development</h5>
						</div>
					</div>
					<div className="col-md-4 col-sm-12 mb-xs-2rem text-center">
						<div
							className="prod-card wow zoomIn ml-auto ml-md-0 mr-auto mr-md-0"
							data-wow-delay="600ms"
						>
							<img
								src={appDevIcon}
								alt=""
								width={80}
								height={80}
								className="juno-img"
							/>
							<h5 className="">Web & Mobile</h5>
						</div>
					</div>
					<div className="col-md-4 col-sm-12 mb-xs-2rem text-center">
						<div
							className="prod-card wow zoomIn ml-auto ml-md-0 mr-auto mr-md-0"
							data-wow-delay="600ms"
						>
							<img
								src={techSupportIcon}
								alt=""
								width={80}
								height={80}
								className="juno-img"
							/>
							<h5 className="">Tech Support</h5>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default HomeOurExpertise;
