import React from "react";
import rbt_pink from "../Images/rbt-pink.png";
import { Link } from "react-router-dom";
export const Footer = () => {
	return (
		<div className="pg-footer">
			<footer className="footer">
				<div className="footer-content container">
					<div className="footer-content-column">
						<div className="footer-menu">
							<h2 className="footer-menu-name">LINKS</h2>
							<ul id="menu-quick-links" className="footer-menu-list">
								<li className="menu-item menu-item-type-custom menu-item-object-custom">
									<a rel="noopener noreferrer" href="/">
										Home
									</a>
								</li>
								<li className="menu-item menu-item-type-custom menu-item-object-custom">
									<a rel="noopener noreferrer" href="/services">
										Services
									</a>
								</li>
								<li className="menu-item menu-item-type-custom menu-item-object-custom">
									<a rel="noopener noreferrer" href="/labs">
										Labs
									</a>
								</li>
								<li className="menu-item menu-item-type-custom menu-item-object-custom">
									<a rel="noopener noreferrer" href="/resources">
										Resources
									</a>
								</li>
								<li className="menu-item menu-item-type-custom menu-item-object-custom">
									<a rel="noopener noreferrer" href="/careers">
										Careers
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div className="footer-content-column ">
						<div className="footer-call-to-action">
							<h2 className="footer-call-to-action-title mb-4">
								{" "}
								Get in touch with us!
							</h2>
							<a
								className="btn btn-outline-info rounded text-white  footer-btn"
								href="/contact-us"
								target="_self"
							>
								{" "}
								Get in Touch{" "}
							</a>
						</div>
					</div>
					<div className="footer-content-column pr-0">
						<div className="footer-call-to-action contact">
							<h2 className="footer-call-to-action-title"> Contact</h2>
							<div className="footer-call-to-action-link-wrapper">
								<h2 className="footer-call-to-action-title mt-4"> Chennai</h2>
								<br />
								<a
									className="footer-call-to-action-link"
									href="https://www.google.com/maps/place/RedBlackTree/@13.0362803,80.2756204,17z/data=!3m1!4b1!4m6!3m5!1s0x3a5267d549473a4d:0x37958d675cf22d42!8m2!3d13.0362803!4d80.2756204!16s%2Fg%2F1q2whsrz5?entry=ttu"
									target="_self"
								>
									#8, Lalitha Nagar 1<sup>st</sup> Street, <br />
									Papanasam Sivan Road, Santhome,
									<br /> Chennai 600004.
								</a>
								<br />

								<br />
								<span className="footer-call-to-action-link mt-4">
									hello@redblacktree.com
								</span>
								<br />
								<span className="footer-call-to-action-link">
									+91-44-4353 3706
								</span>
							</div>
						</div>
					</div>
					{/* <div className="footer-content-column pr-0">
						<div className="footer-call-to-action contact">
							<h2 className="footer-call-to-action-title">&nbsp;</h2>
							<div className="footer-call-to-action-link-wrapper">
								<h2 className="footer-call-to-action-title mt-4">Bangalore</h2>
								<br />
								<div className="footer-call-to-action-link">
									#13-14, 1<sup>st</sup> Floor, SV Complex,
									<br /> Navodaya Nagar, J.P.Nagar 7<sup>th</sup> Phase,
									<br /> Bangalore 560078.
								</div>
							</div>
						</div>
					</div> */}
				</div>
				<div className="footer-copyright ">
					<div className="footer-copyright-wrapper container d-flex align-items-center justify-content-center justify-content-md-between  flex-column flex-md-row">
						<div className=" wrapper2 flex-column flex-md-row">
							<a href="/">
								<img
									src={rbt_pink}
									alt="logo"
									title="Logo"
									className="logo-default "
								/>
							</a>
							<div className="social-media-links">
								<a
									href="https://www.linkedin.com/company/redblacktree"
									target="_blank"
									rel="noreferrer"
								>
									<i className="fab fa-linkedin-in"></i>
								</a>
								<a
									href="https://twitter.com/rbt_tech"
									target="_blank"
									rel="noreferrer"
								>
									<i className="fab fa-twitter"></i>
								</a>
								<a
									href="https://www.facebook.com/RedBlackTree.fb"
									target="_blank"
									rel="noreferrer"
								>
									<i className="fab fa-facebook-f" />
								</a>
								<a
									href="https://www.instagram.com/rbt_tech/"
									target="_blank"
									rel="noreferrer"
								>
									<i className="fab fa-instagram"></i>
								</a>
							</div>
							<span className="footer-copyright-link">
								{" "}
								© RedBlackTree Technologies Pvt. Ltd.{" "}
							</span>
						</div>
						<a
							className="footer-copyright-link pr-md-5 mr-md-4"
							href="/privacy-policy"
							target="_blank"
						>
							{" "}
							Privacy Policy{" "}
						</a>
					</div>
				</div>
			</footer>
		</div>
	);
};
