import React from "react";

const CaseBoxes = ({ caseStudies }) => {
	return (
		<div>
			<div className="row">
				<div className="col-12 service-case-cards ">
					{caseStudies.map((caseStudy, index) => {
						return (
							<a
								href={`/resources/${caseStudy.type}/${caseStudy.id}`}
								className=" case-item wow zoomIn"
								data-wow-delay={`${index * 0.2}s`}
							>
								<img alt={caseStudy?.name} src={caseStudy.image} />
								<div className="bg-overlay " />

								<div className="news-text-box">
									<span>
										<h6 className="news-title mb-3">{caseStudy.name}</h6>
									</span>

									<p className="para alt-font mb-3">{caseStudy.description}</p>
									<a
										href={`/resources/${caseStudy.type}/${caseStudy.id}`}
										className="mt-3 font-weight-600 learn-more-btn"
									>
										Learn more
										<i class="fa fa-arrow-right ml-2" aria-hidden="true"></i>
									</a>
								</div>
							</a>
						);
					})}
				</div>
			</div>
			<div className="row">
				<div className="col-12 justify-content-end d-flex">
					<a
						href="/resources"
						className="wow fadeInUp mb-4 learn-btn mt-5 text-right"
						data-wow-delay="1s"
					>
						Read more Case Studies{" "}
						<i class="fa fa-arrow-right ml-2" aria-hidden="true"></i>
					</a>
				</div>
			</div>
		</div>
	);
};

export default CaseBoxes;
