import React from "react";
import "../components/Styles/careers.css";
import { Footer } from "../components/footer";
import { Header } from "../components/Header/header";
import PeopleGallery from "../components/PeopleGallery";
import CareersBanner from "../components/CareersComponents/CareersBanner";
import LifeAtRBT from "../components/CareersComponents/LifeAtRBT";
import CareersRequest from "../components/CareersComponents/CareersRequest";

export const Careers = () => {
	return (
		<div>
			<>
				<Header />
				<CareersBanner />
				<PeopleGallery />
				<LifeAtRBT />
				<CareersRequest />
				<Footer />
			</>
		</div>
	);
};
