import React from "react";
import juno from "../../Images/Juno (1).png";
import junoDetails from "../../Images/juno-details1.png";
const JunoDetails = (props) => {
	return (
		<section className="bg-lightgrey juno-details">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-7 col-md-12">
						<div className="row">
							<div className="col-md-12  pl-0 ">
								<div
									className="main-title wow fadeIn style-two"
									data-wow-delay="300ms"
								>
									<div className="juno-logo">
										<img
											src={juno}
											alt=""
											width={80}
											height={80}
											className="juno-img"
										/>
										<h5 className="">Juno</h5>
									</div>
									<h2 className="heading text-left col-md-10 px-0 ">
										Video Analytics & Surveillance <br />
										for Industrial Applications.
									</h2>
									<p className="text-left">
										Juno uses On-Prem AI based on cutting edge Nvidia GPUs and
										Camera-to-Cloud technology to enable real-time video
										analytics and powerful event driven, autonomous systems and
										applications.
									</p>
									<div className="juno-points">
										<span>*</span>
										<p className="text-left">
											We are in a closed beta now and taking on early adopter
											customers as we build out the product.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-5 col-md-12 ">
						<div className="">
							<img alt="stats" src={junoDetails} className="about-img juno" />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default JunoDetails;
