import React, { useState, useEffect } from "react";
import "../components/Styles/lab.css";
import { Footer } from "../components/footer";
import { Header } from "../components/Header/header";
import BlogComponent from "../components/Common/BlogComponent";
import BannerComponent from "../components/Common/BannerComponent";
import LabsAbout from "../components/LabsComponents/LabsAbout";
import StartUpStudio from "../components/LabsComponents/StartUpStudio";
import LabsProducts from "../components/LabsComponents/LabsProducts";
import MethodicalDetails from "../components/LabsComponents/MethodicalDetails";
import JunoDetails from "../components/LabsComponents/JunoDetails";
import { getBlogMediaById, getBlogs, getCategories } from "../api";

export const Labs = () => {
	const [ourBlogs, setOurBlogs] = useState([]);
	const [blogs, setBlogs] = useState([]);
	const [allCategories, setAllCategories] = useState([]);

	useEffect(() => {
		getOurBlogs();
	}, []);
	useEffect(() => {
		getBlogCategories();
	}, [ourBlogs]);
	const getOurBlogs = async () => {
		const blogs = await getBlogs();
		if (Array.isArray(blogs?.data) && blogs?.data?.length > 0) {
			const updatedBlogs = await Promise.all(
				blogs.data.map(async (blog) => {
					const imageUrl = await getBlogMediaById(blog?.featured_media);
					return { ...blog, imageUrl };
				})
			);
			setOurBlogs(updatedBlogs);
		}
	};

	useEffect(() => {
		getBlogsByCategory();
	}, [allCategories]);

	const getBlogCategories = async () => {
		const categories = await getCategories();
		setAllCategories(categories);
	};

	const getBlogsByCategory = async () => {
		const ArticleCategoryId = findCategoryId("Article");
		const categoryBlogs = await getPostsByCategory(ArticleCategoryId);
		// get latest 3 blogs
		if (Array.isArray(categoryBlogs) && categoryBlogs.length > 0) {
			const latestBlogs = categoryBlogs?.slice(0, 3);
			setBlogs(latestBlogs);
		} else {
			setBlogs([]);
		}
		// setCaseStudies(getPostsByCategory(caseStudyCategoryId));
	};
	// funtion to find id of the category by name
	const findCategoryId = (name) => {
		if (Array.isArray(allCategories) && allCategories.length > 0) {
			const category = allCategories?.find(
				(category) => category.name === name
			);
			return category?.id;
		} else {
			return null;
		}
	};

	// function to get posts by article id
	const getPostsByCategory = (id) => {
		const posts = ourBlogs.filter((blog) => blog.categories.includes(id));
		return posts;
	};
	return (
		<div>
			<Header />
			<BannerComponent
				id="lab-home"
				isOverlayApplied={true}
				overlayOpacity="opacity-6"
				heading="RedBlackTree Labs - building scalable solutions"
				description="Our moonshot factory where we design and build solutions for
				hard problems."
				eventPageName="labs"
			/>
			<LabsAbout />
			<StartUpStudio />
			<LabsProducts />
			<MethodicalDetails />
			<JunoDetails />
			<BlogComponent
				className="bg-light-gray"
				heading="News, Trends & More"
				description="The latest updates and articles from our engineering teams."
				blogs={blogs}
			/>
			<Footer />
		</div>
	);
};
