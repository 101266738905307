import React from "react";
import { raiseGAEvent } from "../../Events/Event";

const BannerComponent = (props) => {
	return (
		<section id={props?.id}>
			{props?.isOverlayApplied && (
				<div className={`bg-overlay bg-black ${props?.overlayOpacity}`} />
			)}
			<div className="container d-flex align-items-center justify-content-center text-left h-100">
				<div
					className="col-12 col-md-12 px-0 section-heading about-fadeIn wow"
					data-wow-delay=".5s"
				>
					<div className="mb-4 mb-md-0">
						<h2
							className="heading wow fadeIn text-white font-weight-500"
							data-wow-delay=".6s"
						>
							{props?.heading}
						</h2>
						<div className="wow zoomIn p-0" data-wow-delay=".8s">
							<h5 className="mb-4 about-p">{props?.description} </h5>
						</div>
					</div>
					<div className="talk-to-us">
						<a
							href="/contact-us"
							className=" btn btn-setting btn-outline-info rounded text-white wow zoomIn"
							data-wow-delay=".8s"
							onClick={() =>
								raiseGAEvent(
									"Button Click",
									"Talk to us",
									`${props?.eventPageName} Page`
								)
							}
						>
							Talk To Us{" "}
						</a>
					</div>
				</div>
			</div>
		</section>
	);
};

export default BannerComponent;
