// Dev BaseUrl
// export const wordPress_Base_Url = "https://blog.redblacktree.net/wp-json/wp/v2";
// Prod Base Url
export const wordPress_Base_Url = "https://cms.redblacktree.com/wp-json/wp/v2";

export const PostsUrl = `${wordPress_Base_Url}/posts`;

export const getBlogMediaUrl = `${wordPress_Base_Url}/media`;

export const AddCommentUrl = `${wordPress_Base_Url}/comments`;

export const getCategoriesUrl = `${wordPress_Base_Url}/categories`;

export const getTagsUrl = `${wordPress_Base_Url}/tags`;
