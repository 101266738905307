import React from "react";

const LabsAbout = (props) => {
	return (
		<section id="aboutus" className="bg-light-gray3 about lab-about h-auto">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div
							className="main-title style-two text-center text-md-left wow fadeIn col-12 col-md-12"
							data-wow-delay=".6s"
						>
							<div className="mb-4 mb-md-0">
								<h2 className="heading wow fadeIn">RedBlackTree Labs</h2>
								<div className="wow zoomIn" data-wow-delay=".8s">
									<span className="mb-4 about-p text-dark home">
										We bring data, clean design, cutting edge technology and
										great people together to solve problems for broad horizontal
										markets. This is our own humble, but very capable, startup
										studio and moonshot factory.
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default LabsAbout;
