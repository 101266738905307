import React, { useEffect, useState } from "react";
import { Footer } from "../components/footer";
import { useParams } from "react-router-dom";
import "../components/Styles/agile.css";
import "../components/Styles/case.css";
import {
	getBlogMediaById,
	getBlogs,
	getCategories,
	getComments,
	getPostTags,
} from "../api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import classes from "../components/Styles/BlogDetails.module.css";
import DOMPurify from "dompurify";
import HeaderAppear from "../components/Header/HeaderAppear";

export const BlogDeatils = () => {
	let { id } = useParams();

	const [data, setData] = useState({});
	const [author, setAuthor] = useState({});
	const [comments, setComments] = useState([]);
	const [message, setMessage] = useState("");
	const [allCategories, setAllCategories] = useState([]);
	const [tags, setTags] = useState([]);
	const [recentBlogs, setRecentBlogs] = useState([]);

	useEffect(() => {
		fetchBlogDetails();
		getBlogCategories();
	}, [id]);

	const fetchBlogDetails = async () => {
		const blogs = await getBlogs();
		if (Array.isArray(blogs?.data) && blogs?.data?.length > 0) {
			const updatedBlogs = await addImageUrlsToBlogs(blogs.data);
			setRecentBlogs(updatedBlogs);
			const blog = findBlogById(updatedBlogs, id);
			if (blog) {
				await updateBlogDetails(blog);
			}
		}
	};

	const addImageUrlsToBlogs = (blogs) => {
		return Promise.all(blogs.map(addImageUrlToBlog));
	};

	const addImageUrlToBlog = async (blog) => {
		const imageUrl = await getBlogMediaById(blog?.featured_media);
		return { ...blog, imageUrl };
	};

	const findBlogById = (blogs, id) => {
		return blogs.find((blog) => blog?.id == id);
	};

	const updateBlogDetails = async (blog) => {
		const comments = await getComments(blog?._links?.replies[0]?.href);
		setData(blog);
		setAuthor(blog?.authors[0]);
		setComments(comments);
		if (blog?.tags?.length > 0) {
			const tags = await getPostTags(blog?.id);
			setTags(tags);
		}
	};
	// Get Categories
	const getBlogCategories = async () => {
		const categories = await getCategories();
		setAllCategories(categories);
	};

	return (
		<div>
			<>
				<ToastContainer position="top-center" />
				<HeaderAppear />
				<>
					{/* Page Header */}
					<section className="page_header pb-0 w-100 blog ">
						<div className="container d-flex align-items-center justify-content-center text-left h-100">
							<div
								className="col-12 col-md-12 px-0 section-heading about-fadeIn wow"
								data-wow-delay=".5s"
							>
								<div className="mb-4 mb-md-0">
									<h2
										className="heading wow fadeIn text-white font-weight-500 mb-5"
										data-wow-delay=".6s"
									>
										{data && data?.title?.rendered}
									</h2>
									<div className="wow zoomIn p-0" data-wow-delay=".2s">
										<div className="author bottom-0 ">
											<span className="post">
												<img
													src={
														author && author?.avatar_url?.url
															? author?.avatar_url?.url
															: author?.avatar_url
													}
													alt={author?.first_name}
												/>
											</span>
											<div className="author-name mx-2">
												<h5 className=" mb-1 about-p font-weight-600 ">
													{author?.display_name}
													{author?.display_name && ", "}
													<span className="desc">{author?.description}</span>
												</h5>
												<span className="date d-block ">
													{data?.date &&
														new Date(data?.date).toLocaleDateString("en-US", {
															day: "numeric",
															month: "short",
															year: "numeric",
														})}
												</span>
											</div>
										</div>

										<div className="pub-date mt-3">
											<span></span>
										</div>
									</div>
									&nbsp; <br /> &nbsp;
								</div>
							</div>
						</div>
					</section>
					{/* Page Header */}
					{/* Blog starts */}
					<section id="blog" className="pt-4 text-left">
						<div className="container">
							<div className="row">
								<div className="col-lg-12">
									{/* Blog List Inner */}
									{/* {data?.imageUrl ? (
										<div className="blog-listing-inner heading-space-half">
											<div className="image hover-effect">
												<img
													src={
														data && data?.imageUrl ? data && data?.imageUrl : ""
													}
													alt="blog-img"
												/>
											</div>
										</div>
									) : null} */}
								</div>
								{/* Blog Left Listing */}
								<div className="col-lg-12 col-md-12">
									<div className="blog-box heading-space-half m-md-0 ">
										{/* Blog List Inner */}
										<div className="blog-listing-inner news_item p-5">
											<div className="news_desc">
												<div
													className={"mb-35px " + classes.content}
													dangerouslySetInnerHTML={{
														__html: DOMPurify.sanitize(
															data?.content?.rendered,
															{ USE_PROFILES: { html: true } }
														),
													}}
												></div>

												{/* <div className="profile-authors heading-space-half">
													<h4 className="text-capitalize color-black mb-35px">
														Comments
													</h4>
													{comments && comments?.length > 0 ? (
														comments.map((comment, index) => {
															return (
																<div
																	className="any-profile mb-30px"
																	key={index}
																>
																	<div className="profile-photo">
																		<img
																			src={comment?.author_avatar_urls[96]}
																			alt="Comments"
																		/>
																	</div>
																	<div className="profile-text pl-3">
																		<h6 className="mb-1">
																			<span className="color-black mr-2">
																				{comment?.author_name}
																			</span>
																			<span className="color-black mr-2">
																				<i className="fa fa-calendar color-green mr-2" />
																				{new Date(
																					comment?.date
																				).toLocaleDateString("en-US", {
																					day: "numeric",
																					month: "long",
																					year: "numeric",
																				})}
																			</span>
																			<span className="color-black mr-2">
																				<time>
																					{new Intl.DateTimeFormat("en-US", {
																						hour: "numeric",
																						minute: "numeric",
																						timeZone: "UTC", // replace with your timezone
																						hour12: true,
																					}).format(new Date(comment?.date))}
																				</time>
																			</span>
																		</h6>
																		<div
																			className="mt-0 mb-0 color-grey"
																			dangerouslySetInnerHTML={{
																				__html: comment?.content?.rendered,
																			}}
																		></div>
																	</div>
																</div>
															);
														})
													) : (
														<p>No Comments</p>
													)}
												</div> */}
												{/* <div className="text-left w-100">
													<h4 className="color-black text-capitalize mt-2 mb-2">
														{" "}
														add comments
													</h4>
													<p className="color-grey line-height-25px mb-2rem">
														Your Email address will not be published
													</p>
												</div>
												<div className="col-sm-12 p-0" id="result" />
												<div className="company-contact-form">
													<form
														className="contact-form-outer"
														onSubmit={(e) => handleSubmit(e)}
													>
														<div className="row">
															<input type="hidden" id="postId" value={id} />
															<div className="col-md-6 col-sm-12">
																<div className="contact-form-textfield pb-4">
																	<input
																		type="text"
																		placeholder="Name"
																		className="form-control"
																		required=""
																		id="name"
																		name="name"
																	/>
																</div>
															</div>
															<div className="col-md-6 col-sm-12">
																<div className="contact-form-textfield pb-4">
																	<input
																		type="email"
																		placeholder="Email"
																		className="form-control"
																		required=""
																		id="email"
																		name="email"
																	/>
																</div>
															</div>
															<div className="col-lg-12">
																<div className="contact-form-textfield pb-4">
																	<textarea
																		placeholder="Message"
																		className="form-control message"
																		id="message"
																		name="message"
																		onChange={(e) => setMessage(e.target.value)}
																	/>
																</div>
															</div>
															<div className="col-lg-12 pt-xs-25px text-center">
																<button
																	type="submit"
																	className="btn-setting btn-scale btn-green text-white w-100"
																>
																	<b className="font-weight-normal">
																		Submit Comment
																	</b>
																</button>
															</div>
														</div>
													</form>
												</div> */}
											</div>
										</div>
									</div>
								</div>
								{/* Blog Widgets */}
								{/* <div className="col-lg-4 col-md-5">
									<div className="text-left">
										<div
											className="widget heading-space-half wow fadeIn"
											data-wow-delay="300ms"
										>
											<form className="search-box">
												<p className="color-black mb-4">
													Explore what the world is searching. Enter a search
													term or a topic.
												</p>
												<div className="input-group">
													<input
														type="search"
														className="form-control"
														placeholder="Enter keyword..."
														required=""
													/>
													<button type="submit" className="search-addon">
														<i className="fa fa-search line-height-35px" />{" "}
													</button>
												</div>
											</form>
										</div>
										<div
											className="widget heading-space-half wow fadeIn"
											data-wow-delay="350ms"
										>
											<h5 className="color-black font-weight-600 text-capitalize mb-4">
												Blog Posts
											</h5>
											{recentBlogs && recentBlogs?.length > 0 ? (
												recentBlogs.map((item, index) => {
													return (
														<div
															className="single-post mb-3 wow fadeInUp"
															data-wow-delay={`${index * 0.2}s`}
															key={index}
														>
															<a
																href={`/resources/blog/${item.id}`}
																className="post"
															>
																<img
																	src={item.imageUrl}
																	alt={item?.title?.rendered}
																/>
															</a>
															<div className="text">
																<a
																	href={`/resources/blog/${item.id}`}
																	className="color-grey"
																>
																	{item?.title?.rendered}
																</a>
																<span>
																	{new Date(item?.date).toLocaleDateString(
																		"en-US",
																		{
																			day: "numeric",
																			month: "long",
																			year: "numeric",
																		}
																	)}
																</span>
															</div>
														</div>
													);
												})
											) : (
												<p>No Recent Blogs</p>
											)}
										</div>
										<div
											className="widget heading-space-half wow fadeIn"
											data-wow-delay="400ms"
										>
											<h5 className="color-black font-weight-600 text-capitalize">
												Categories
											</h5>
											<div>
												{allCategories && allCategories?.length > 0 ? (
													allCategories.map((category, index) => {
														return (
															<div
																className="categories-name wow fadeIn"
																data-wow-delay={`${index * 0.2}s`}
																key={index}
															>
																<span>
																	<div className="font-16 color-grey text-capitalize">
																		<i className="fa fa-angle-right font-14 color-blue mr-1" />{" "}
																		{category?.name}{" "}
																		<span>{category?.count}</span>{" "}
																	</div>
																</span>
															</div>
														);
													})
												) : (
													<p>No Categories</p>
												)}
											</div>
										</div>
										<div
											className="widget heading-space-half wow fadeIn"
											data-wow-delay="450ms"
										>
											<h5 className="color-black font-weight-600 mb-4 text-capitalize">
												popular tags
											</h5>
											<div>
												<ul className="tags">
													{tags && tags?.length > 0 ? (
														tags.map((tag, index) => {
															return (
																<li
																	key={index}
																	className=" wow zoomIn"
																	data-wow-delay={`${index * 0.2}s`}
																>
																	<div className="font-13 text-capitalize">
																		{tag?.name}
																	</div>
																</li>
															);
														})
													) : (
														<p>No Tags</p>
													)}
												</ul>
											</div>
										</div>
									</div>
								</div> */}
							</div>
						</div>
					</section>
					{/* Blog ends */}
				</>

				{/* Footer starts */}
				<Footer />
				{/* Footer ends */}
			</>
		</div>
	);
};
