import React from "react";
import methodical from "../../Images/logos/methodical-logo.svg";
import juno from "../../Images/Juno (1).png";

const LabsProducts = (props) => {
	return (
		<section id="products" className="">
			<div className="container">
				<div className="row">
					<div className="col-md-12 ">
						<div
							className="main-title wow fadeIn style-two"
							data-wow-delay="300ms"
						>
							<h2 className="heading text-left">Products</h2>
							<p className="text-left">
								At RedBlackTree Labs, we prototype, experiment and iterate
								rapidly over our ideas to achieve product-market fit. We always
								aim to build sustainable businesses around our products. We
								currently have two products in the works.
							</p>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-4 col-sm-12 mb-xs-2rem text-center">
						<a
							href="https://www.methodical.ai/"
							target="_blank"
							rel="noreferrer"
							className="prod-card wow zoomIn methodical ml-auto ml-md-0 mr-auto mr-md-0"
							data-wow-delay="500ms"
						>
							<img src={methodical} alt="" width={80} height={80} />
							<h5 className="">Methodical</h5>
						</a>
					</div>
					<div className="col-md-4 col-sm-12 mb-xs-2rem text-center">
						<div
							className="prod-card wow zoomIn ml-auto ml-md-0 mr-auto mr-md-0"
							data-wow-delay="600ms"
						>
							<img
								src={juno}
								alt=""
								width={80}
								height={80}
								className="juno-img"
							/>
							<h5 className="">Juno</h5>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default LabsProducts;
