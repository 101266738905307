import React from "react";
import methodical from "../../Images/logos/methodical-logo.svg";
import juno from "../../Images/Juno (1).png";
import { raiseGAEvent } from "../../Events/Event";
const HomeServices = (props) => {
	return (
		<section className="mt-0 home-services pt-0">
			<div className="home-services-img">
				<div className="bg-overlay bg-black opacity-5" />

				<div className="container position-relative h-100 ">
					<div className="row align-items-center position-relative ">
						<div className="col-lg-12 col-md-12 split-box-container-setting text-left pt-md-0 ">
							<div
								className="main-title wow fadeIn style-two text-left pt-5"
								data-wow-delay="300ms"
							>
								<h2 className="heading mb-3 text-white ">Our Services</h2>
								<p className="mb-3 text-white col-md-6 col-lg-4 p-0">
									We bring over 2 decades of software consulting, development
									and delivery experience to the table.
								</p>
								<p className="mb-5 text-white col-md-6 col-lg-4 p-0">
									We work with startups, OEMs and large enterprise organizations
									across the US, Europe, Asia and India.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="cards">
				<div className="container d-flex justify-content-center">
					<div className="row">
						<div className="col-md-4">
							<div className="card wow fadeInUp" data-wow-delay="0.4s">
								<div className="card-body">
									<h5 className="card-title">IT Services</h5>
									<p className="card-text mb-5">
										High quality tech services, delivered on time.
									</p>
									<p className="card-text it">
										{" "}
										<i class="fas fa-asterisk"></i>{" "}
										<span>Software Consulting</span>
									</p>
									<p className="card-text it">
										{" "}
										<i class="fas fa-asterisk"></i>
										<span>Low Code Mendix Development</span>
									</p>
									<p className="card-text it">
										{" "}
										<i class="fas fa-asterisk"></i>{" "}
										<span>Mobile & Web Development</span>
									</p>
									<p className="card-text it">
										<i class="fas fa-asterisk"></i>{" "}
										<span>High Performance Databases</span>
									</p>
									<p className="card-text it">
										<i class="fas fa-asterisk"></i>{" "}
										<span>Cloud Infrastructure Maintenance</span>
									</p>
									<p className="card-text it">
										<i class="fas fa-asterisk"></i>{" "}
										<span>Tech Support & Helpdesk Services</span>
									</p>
									<a
										href="/services"
										onClick={() =>
											raiseGAEvent(
												"Button Click",
												"Learn more - IT Services",
												"Home Page"
											)
										}
									>
										Learn more
										<i
											className="fa fa-arrow-right ml-2 mt-4"
											aria-hidden="true"
										></i>
									</a>
								</div>
							</div>
						</div>
						<div className="col-md-4">
							<div className="card wow fadeInUp" data-wow-delay="0.6s">
								<div className="card-body">
									<h5 className="card-title">Labs</h5>
									<p className="card-text mb-5">
										Design, prototype, experiment and build new products.
									</p>
									<p className="card-text it">
										<i class="fas fa-asterisk"></i> <span>Design</span>
									</p>
									<p className="card-text it">
										<i class="fas fa-asterisk"></i>{" "}
										<span>Rapid Prototyping</span>
									</p>
									<p className="card-text it">
										<i class="fas fa-asterisk"></i>{" "}
										<span>Product Development</span>
									</p>
									<p className="card-text it">
										<i class="fas fa-asterisk"></i>{" "}
										<span>Performance & Scaling</span>
									</p>
									<a
										href="/labs"
										onClick={() =>
											raiseGAEvent(
												"Button Click",
												"Learn more - Labs",
												"Home Page"
											)
										}
									>
										Learn more
										<i
											className="fa fa-arrow-right ml-2 mb-5"
											aria-hidden="true"
										></i>
									</a>
								</div>
							</div>
						</div>
						<div className="col-md-4">
							<div className="card wow fadeInUp" data-wow-delay="0.8s">
								<div className="card-body">
									<h5 className="card-title">Products</h5>
									<p className="card-text mb-5">
										Breaktrhough solutions to build efficiences and reduce
										costs.
									</p>
									<p className="card-text it">
										<a
											href="https://www.methodical.ai"
											target="_blank"
											rel="noreferrer"
										>
											<img src={methodical} alt="" width={80} height={80} />{" "}
											<span>Methodical</span>
										</a>
									</p>
									<p className="card-text it">
										{" "}
										<img
											src={juno}
											alt=""
											width={80}
											height={80}
											className="juno-img"
										/>{" "}
										<span>Juno</span>
									</p>
									<a
										href="/labs"
										onClick={() =>
											raiseGAEvent(
												"Button Click",
												"Learn more - Products",
												"Home Page"
											)
										}
									>
										Learn more
										<i
											className="fa fa-arrow-right ml-2 mb-5"
											aria-hidden="true"
										></i>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default HomeServices;
