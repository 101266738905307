import React from "react";
import { Route, Routes } from "react-router-dom";
import { Services } from "../Pages/services";
import { AgileDevelopment } from "../Pages/Service/agileDevelopment";
import { BigData } from "../Pages/Service/bigData";
import { LowCode } from "../Pages/Service/lowCode";
import { AppDevMain } from "../Pages/Service/appDevMain";
import { ProductEngineering } from "../Pages/Service/productEngineering";

const ServiceRoot = (props) => {
	return (
		<Routes>
			<Route path="/" index element={<Services />} />
			<Route exact path="/agile-development" element={<AgileDevelopment />} />
			<Route path="/big-data" element={<BigData />} />
			<Route path="/low-code" element={<LowCode />} />
			<Route
				exact
				path="/application-development-maintenance"
				element={<AppDevMain />}
			/>
			<Route path="/product-engineering" element={<ProductEngineering />} />
		</Routes>
	);
};

export default ServiceRoot;
