import React from "react";

const LifeAtRBT = (props) => {
	return (
		<section className="life">
			<div id="testim" className="testimonial_content content_padding">
				<div className="container ">
					<div className="main-title wow fadeIn  text-left style-two mb-4 ">
						<h2 className="heading servicesheader w_serviceheader tech_head">
							Life at RedBlackTree{" "}
						</h2>
					</div>
					<div className="row paper_effect_div">
						<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12  text-center px-0 mb-5 mb-md-0 ">
							<div className="people-block block-1"></div>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 people-content content mb-5 mb-md-0">
							<h3 className="min-center">Solve tough problems</h3>
							<br />
							<div className="min-center">
								We use technology to solve tough problems - to bring useful and
								impactful new products to global markets. Engineering is
								challenging and fun in our fast paced, dynamic work environment.
							</div>
						</div>
					</div>
					<div className="row paper_effect_div" id="lfdiv">
						<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 people-content content mb-5 mb-md-0">
							<h3 className="min-center">Learn and Grow</h3>
							<br />
							<div className="min-center">
								Learning never stops at RedBlackTree. We learn by
								experimentation and discovery, we learn from our team mates, we
								learn by holding ourselves to the results we produce.
							</div>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12  text-center px-0 mb-5 mb-md-0">
							<div className="people-block block-2"></div>
						</div>
					</div>
					<div className="row paper_effect_div">
						<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 text-center px-0 mb-5 mb-md-0">
							<div className="people-block block-3"></div>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 people-content content mb-5 mb-md-0">
							<h3 className="min-center">Have the time of your life</h3>
							<br />
							<div className="min-center">
								Our people and our open, liberal and result-focussed culture
								makes us a great place to work. Our people are smart, generous,
								friendly and passionate about making a difference. We work hard
								on our culture - on having fun while making a difference and
								changing the world.
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default LifeAtRBT;
